import React from 'react';
import { PostsByWeek } from './PostsByWeek';
import { add } from '../../../../assets/scripts/dateLib';

/**
 * Builds the current date's calendar items
 * @typedef {{
 * targetDate: Date
 * now: Date
 * previousMonth: number
 * nextMonth: number
 * daysInMonth: number
 * dayOfWeek: number
 * startOfCalendar: number
 * weeksInMonth: number
 * currentWeekInMonth: number
 * isoWeek: number
 * nextWeek: number
 * prevWeek: number
 * resetStartOfCalendar: Function
 * calcWeeksInMonth: Function
 * }} Calendar
 * @param {object} argv object defining properties to use in this function
 * @param {Array} argv.weeksInMonth collection of posts for this social group
 * @param {string} argv.appId unique application id for building out urls
 * @param {string} argv.grpId the application's social group id for building out urls
 * @param {Array} argv.socialGroupIdentitys collection of social identities to use
 * @param {Calendar} argv.calendar custom calendar class
 * @param {string} argv.referer encoded uri that referred to this page
 * @param {boolean} argv.canEdit whether or not the application has a subscription and can be used/modified
 * @param {Function} argv.onChangeEdit function to track/update the state upon text field changes
 * @param {Function} argv.onClickDelete function to control the deletion of the post
 * @param {Function} argv.onClickReschedule function to control rescheduling the post
 * @param {Function} argv.onClickCopy function to control the copying of the post
 * @param {Function} argv.onClickCreatePost function to trigger a post creation
 * @param {Function} argv.onMoveReschedulePost triggered when the post is moved in a calendar
 * @param {boolean} argv.isMoving whether or not the post is scheduling days
 * @returns {HTMLElement} html
 */
export function PostsByMonth ({
    weeksInMonth,
    appId,
    grpId,
    socialGroupIdentitys,
    calendar,
    referer,
    canEdit,
    onChangeEdit,
    onClickDelete,
    onClickReschedule,
    onClickCopy,
    onClickCreatePost,
    onMoveReschedulePost,
    isMoving
}) {

    let weeks = [],
        currentDate = calendar.startOfCalendar,
        targetDate = calendar.targetDate;

    // loop over the weeks in the month
    for (var w = 0; w < weeksInMonth.length; w++) {
        weeks.push(<tr key={weeksInMonth[`${w}`].id}>
            <PostsByWeek
                weeksDays={weeksInMonth[`${w}`].days}
                appId={appId}
                grpId={grpId}
                socialGroupIdentitys={socialGroupIdentitys}
                targetDate={targetDate}
                currentDate={add(currentDate, w*7, 'days')}
                referer={referer}
                canEdit={canEdit}
                onChangeEdit={onChangeEdit}
                onClickDelete={onClickDelete}
                onClickReschedule={onClickReschedule}
                onClickCopy={onClickCopy}
                onClickCreatePost={onClickCreatePost}
                onMoveReschedulePost={onMoveReschedulePost}
                isMoving={isMoving}
            />
        </tr>);
    }

    // reset calendar start date to original date
    currentDate = calendar.resetStartOfCalendar();

    return weeks;
}
