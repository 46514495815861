import { get, put, post, del } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';

/**
 * Get the current customer's stripe billing info
 * @param {string} appId application id
 * @returns {Promise} promise with stripe billing info
 */
export const getCustomerBillingInfo = async (appId) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await get({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/billing/info`,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Get the current customer's stripe subscription info
 * @param {string} appId application id
 * @returns {Promise} promise with stripe subscription info
 */
export const getSubscriptionInfo = async (appId) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await get({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/subscription`,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Pull the list of available countries from Stripe
 * @returns {Promise} promise with stripe country abbreviation list
 */
export const getCountryList = async () => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await get({
            apiName: 'stitchz-web-api',
            path: '/app/billing/countries',
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Creates a new customer with Stripe and gets the customer details
 * @param {object} customer customer details used to create the new customer at Stripe
 * @returns {Promise} promise which resolves to the new stripe customer object
 */
export const saveCustomerDetails = async (customer) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await post({
            apiName: 'stitchz-web-api',
            path: '/app/billing',
            options: {
                headers: {
                    Authorization: token
                },
                body: {
                    userId: customer.username,
                    name: customer.name,
                    email: customer.email,
                    address: customer.address,
                    city: customer.city,
                    state: customer.state,
                    postal: customer.postal,
                    country: customer.country,
                    subscriptionCustomerId: customer.subscriptionCustomerId
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Save and Create a new payment method for this application
 * @param {string} appId application id
 * @param {object} intent payment method info
 * @param {object} customer AWS amplify user token
 * @param {object} billingCustomer customer to bill to
 * @returns {Promise} promise resolving the updated payment info
 */
export const saveNewPaymentMethodCustomer = async (appId, intent, customer, billingCustomer) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await put({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/billing`,
            options: {
                headers: {
                    Authorization: token
                },
                body: {
                    paymentMethodId: intent.paymentId,
                    subscriptionType: intent.subscriptionType,
                    subscriptionCustomerId: customer.id,
                    email: customer.email,
                    name: billingCustomer.name,
                    address: billingCustomer.address_line1,
                    city: billingCustomer.address_city,
                    state: billingCustomer.address_state,
                    postal_code: billingCustomer.address_postal,
                    country: billingCustomer.address_country
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Setup a new credit card in preparation for subscribing to the service
 * @param {string} appId application id
 * @returns {Promise} promise resolving the payment intent at Stripe
 */
export const setupNewPaymentIntent = async (appId) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await get({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/billing/intent`,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Saves the updated payment info
 * @param {string} appId application id
 * @param {object} billingDetails collection of billing details that stripe accepts
 * @returns {Promise} promise response with updated info
 */
export const savePaymentMethod = async (appId, billingDetails) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await put({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/billing/info`,
            options: {
                headers: {
                    Authorization: token
                },
                body: billingDetails
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Sends the delete request
 * @param {string} appId application id
 * @returns {Promise} promise response with deleted info
 */
export const deletePaymentMethod = async (appId) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await del({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/billing/info`,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Save the new subscription to the database
 * @param {string} appId application id
 * @param {number} subscriptionType subscription type id
 * @returns {Promise} promise to resolve the save response
 */
export const saveSubscription = async (appId, subscriptionType) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await put({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/subscription`,
            options: {
                headers: {
                    Authorization: token
                },
                body: {
                    subscriptionType: subscriptionType
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Cancels the subscription plan for this application, saving the changes to the database
 * @param {string} appId application id
 * @returns {Promise} http message response promise
 */
export const cancelSubscription = async (appId) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { statusCode } = await del({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/subscription`,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return statusCode;
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};
