import twitter from 'twitter-text';
import { matchPath } from 'react-router-dom';
import { add, formatDate, getWeekInMonth, getWeekday, parseDateTime } from './dateLib';
import { routes } from './routes';
import Calendar from './calendarLib';
import crypto from 'crypto';

/**
 * Resets the header navigation bar CSS for main pages
 * @returns {void}
 */
export const adjustHeader = () => {
    const header = document.getElementsByClassName('navbar')[0];

    if (header) {
        if (!header.classList.contains('static')) {
            header.classList.add('static');
        }
    }
};

/**
 * Generate a fixed length random string
 * @param {number} byteLength length of bytes
 * @param {string} [stringBase] buffer encoding, i.e. base64, hex, etc.
 * @returns {string} random string
 */
export const generateRandomString = async (byteLength, stringBase = 'base64') => {
    return new Promise((resolve, reject) => {
        console.log(byteLength);
        crypto.randomBytes(byteLength, (err, buffer) => {
            console.log(buffer);
            if (err) {
                reject(err);
            } else {
                resolve(buffer.toString(stringBase));
            }
        });
    });
};

export const generateHash = async (key) => {
    return new Promise((resolve, reject) => {
        try {
            const hash = crypto.createHash('sha256').update(key).digest('hex');
            console.log(hash);

            resolve(hash);
        } catch (err) {
            console.error(err);
            reject(err);
        }
    });
};

/**
 * Checks if the given value is a number type or not
 * @param {*} val the value to check
 * @returns {boolean} true is the value is a valid number
 */
export const isNumber = (val) => {
    return !isNaN(val) || typeof val === 'number';
}

/**
 * Checks if the given value is a string type or not
 * @param {*} val the value to check
 * @returns {boolean} true is the value is a valid string
 */
export const isString = (val) => {
    return typeof val === 'string' || val instanceof String;
}

/**
 * Checks if the given value is an Object type or not
 * @param {*} val the value to check
 * @returns {boolean} true is the value is a valid object type
 */
export const isObject = (val) => {
    if (val === null) { return false;}
    // eslint-disable-next-line no-extra-parens
    return ( (typeof val === 'function') || (typeof val === 'object') );
};

/**
 * Checks whether the given parameter is null/empty or not
 * @param {*} val any value or variable
 * @returns {boolean} true if the value is null or empty, false if it exists
 */
export const isNullOrEmpty = (val) => {
    // eslint-disable-next-line no-extra-parens
    if ((!val && val !== 0)
        || val === 'null'
        || val === 'undefined'
        || typeof val === 'undefined') { // check for null, undefined, empty, false, etc.
        return true;        // if so, then tell the caller this is null or empty
    } else if (isObject(val)) {
        return Object.keys(val).length === 0;
    } else {
        return false;
    }
};

/**
 * format the given string into a camel case format - https://stackoverflow.com/a/2970667
 * @param {string} word string to format in camel case
 * @returns {string} formatted string
 */
export const camelCase = (word) => {
    return word.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index > 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
}

/**
 * Changes the string to past tense
 * @param {string} word string representation of word to past temse
 * @returns {string} updated word
 */
export const pastTense = (word) => {
    if (word.lastIndexOf('ed') > -1) {
        return word;
    } else if (word.lastIndexOf('e') > -1) {
        return `${word}d`;
    } else {
        return `${word}ed`;
    }
}

/**
 * Scrolls the window to the top
 * @returns {void}
 */
export const scrollTop = () => {
    if (window) {
        window.scrollTo(0, 0);
    }
};

/**
 * Counts the number of characters in the message; based on Twitter's character counting algorithm
 * @param {string} tweet share message
 * @param {string} shareUrl url included with message
 * @param {string} fileNames file names included with message
 * @returns {number} integer representing how many characters are in the message
 */
export const getTweetLength = (tweet, shareUrl, fileNames) => {
    // count Twitter characters
    shareUrl = shareUrl ? ' ' + shareUrl : '';

    let tweetLength = twitter.getTweetLength(tweet + shareUrl);

    if (fileNames) {
        tweetLength = tweetLength + 23;
    }

    return tweetLength;
};


/**
 * Gets the nearest ancestor that has an href -- https://stackoverflow.com/a/29412162
 * @param {object} node html target node element
 * @returns {object} parent node with an href
 */
export const nearestAncestorWithHref = (node) => {
    while (node && !node.href) node = node.parentNode;
    return node;
};

/**
 * Gets the nearest ancestor that has matching dataset attribute -- https://stackoverflow.com/a/29412162
 * @param {object} node html target node element
 * @param {string} name string representation of a dataset name to match
 * @returns {object} parent node with the dataset
 */
export const nearestAncestorWithDataset = (node, name) => {
    while ((node && !node.dataset) || (node && !(name in node.dataset))) node = node.parentNode; // eslint-disable-line no-extra-parens
    return node;
};

/**
 * Gets the nearest ancestor that has an id -- https://stackoverflow.com/a/29412162
 * @param {object} node html target node element
 * @returns {object} parent node with an href
 */
export const nearestAncestorWithId = (node) => {
    if (node && node.id) {
        return node;
    } else {
        while (node && !node.id) node = node.parentNode;
        return node;
    }
};

/**
 * Gets the nearest ancestor that has an href -- https://stackoverflow.com/a/29412162
 * @param {object} node html target node element
 * @param {string|Array} className string representation of a class name to match OR an array of class names to match
 * @returns {object|void} parent node with an href or nothing
 */
export const nearestAncestorWithClassname = (node, className) => {
    if (Array.isArray(className)) {
        const origNode = node;
        for (let i = 0; i < className.length; i++) {
            node = origNode; // reset the starting node

            while (node && !node.classList.contains(className[`${i}`])) {
                if (node.parentNode && node.parentNode.classList) {
                    node = node.parentNode;
                } else {
                    node = undefined;
                }
            }

            if (node) {
                return node;
            }
        }
    } else {
        while (node && !node.classList.contains(className)) node = node.parentNode
        return node;
    }
};

/**
 * Extract the query string parameters from the given URI pathname
 * @param {string} pathname uri path to current URL request
 * @returns {object|void} parameter collection from URI or nothing
 */
export const getQueryParams = (pathname) => {
    for (let i = 0; i < routes.length; i++) {
        let matches = matchPath({
            path: routes[`${i}`].path,
            exact: routes[`${i}`].exact
        },
        pathname);

        if (matches) {
            return matches;
        }
    }
};

/**
 * Get a querystring parameter value from the uri
 * @param {string} name name of the parameter value to return
 * @param {string} url uri to extract the parameter from
 * @returns {string|null} the parameter value extracted from the url
 */
export const getQuerystringParameterValueByName = (name, url = window.location.search) => {
    const key = name.replace(/[[]]/g, '\\$&');

    // const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i');
    // const results = regex.exec(url);

    // if (!results) {
    //     return null;
    // }
    // if (!results[2]) {
    //     return '';
    // }

    // return decodeURIComponent(results[2].replace(/\+/g, ' '));

    const params = new Proxy(new URLSearchParams(url), {
        get: (searchParams, prop) => searchParams.get(prop)
    });

    if (params[`${key}`]) {
        return decodeURIComponent(params[`${key}`].replace(/\+/g, ' '));
    } else {
        return '';
    }
};

/**
 * Merge a monthly calendar with a list of posts in user's local date/timezone
 * @param {Calendar} calendar calendar object representing a calendar month
 * @param {Array} posts posts in scope for the month
 * @returns {Array} multidimensional array with posts added to their respective days of the month
 */
export const transformListToMultidimensionalArray = (calendar, posts) => {
    let d = 0,
        weeks = [];

    for (let w = 0; w <= calendar.weeksInMonth; w++) {
        weeks[`${w}`] = {
            id: w,
            type: 'week',
            startOfWeek: add(calendar.startOfCalendar, w * 7, 'days')
        }

        let days = [];

        for (let dow = 0; dow < 7; dow++) {
            days[`${dow}`] = {
                id: `${w}-${dow}`,
                week: w,
                dow: dow,
                type: 'day',
                day: add(calendar.startOfCalendar, d, 'days')
            };

            let today = formatDate(days[`${dow}`].day, 'yyyy-MM-dd');

            const todaysPosts = posts && posts.filter(p => {
                if (p.postDate || p.scheduledPostDate) {
                    // if (isValidDateTime(p.postDate || p.scheduledPostDate)) {
                    const local = formatDate(p.postDate || p.scheduledPostDate, 'yyyy-MM-dd');
                    if (local.indexOf(today) > -1) {
                        if (p.postDate) {
                            p.postDate = parseDateTime(p.postDate);
                        }
                        p.scheduledPostDate = parseDateTime(p.scheduledPostDate);
                        p.addedAt = parseDateTime(p.addedAt);
                        return p;
                    }
                    // }
                    // } else if (p.scheduledPostDate) {
                    //     const local = formatDate(p.scheduledPostDate, 'yyyy-MM-dd');
                    //     if (local.indexOf(today) > -1) {
                    //         p.scheduledPostDate = parseDateTime(p.scheduledPostDate);
                    //         p.addedAt = parseDateTime(p.addedAt);
                    //         return p;
                    //     }
                } else {
                    const local = formatDate(p.addedAt, 'yyyy-MM-dd');
                    if (local.indexOf(today) > -1) {
                        p.addedAt = parseDateTime(p.addedAt);
                        return p;
                    }
                }
            });

            days[`${dow}`].posts = todaysPosts;
            d++;
        }
        weeks[`${w}`].days = days;
    }
    // console.table(weeks);
    // console.log(weeks);

    return weeks;
};

/**
 * Adds a post to the monthly calendar
 * @param {Array} calendarMatrix multidimensional array representing the calendar month
 * @param {Array} post post to add
 * @returns {Array} multidimensional array with posts on their respective days of the month
 */
export const addPostToMultidimensionalArray = (calendarMatrix, post) => {
    if (post.scheduledPostDate) {
        post.scheduledPostDate = parseDateTime(post.scheduledPostDate);
    }
    post.addedAt = parseDateTime(post.addedAt);

    const targetDate = post.scheduledPostDate ? post.scheduledPostDate : post.addedAt,
        week = getWeekInMonth(targetDate) - 1,
        day = getWeekday(targetDate),
        targetWeek = calendarMatrix[`${week}`];

    if (targetWeek) {
        const targetDay = targetWeek.days[`${day}`];

        if (targetDay) {
            targetDay.posts.push(post);
        } else {
            console.log('posts day is not found');
        }
    } else {
        console.log('posts target week not found');
    }

    return calendarMatrix;
};

/**
 * Removes a post from the monthly calendar
 * @param {Array} calendarMatrix multidimensional array representing the calendar month
 * @param {Array} post post to add
 * @returns {Array} multidimensional array with posts on their respective days of the month
 */
export const removePostFromMultidimensionalArray = (calendarMatrix, post) => {
    const targetDate = post.scheduledPostDate ? post.scheduledPostDate : post.addedAt,
        week = getWeekInMonth(targetDate) - 1,
        day = getWeekday(targetDate),
        targetWeek = calendarMatrix[`${week}`];

    if (targetWeek) {
        const targetDay = targetWeek.days[`${day}`];

        if (targetDay) {
            const postIndex = targetDay.posts.findIndex(p => {
                return p.postId === post.postId;
            });

            if (postIndex > -1) {
                targetDay.posts.splice(post, 1);
            }
        }
    }

    return calendarMatrix;
};

/**
 * Finds the appropriate error title from the given error object
 * @param {object} err object containing IdP error info
 * @param {object} msg object containing IdP error message
 * @returns {string} specific error message title
 */
export const getErrorMessageTitle = (err, msg) => {
    if (isString(err) && err.length > 0) {
        if (typeof msg === 'object' && msg.error_user_title) {
            return msg.error_user_title;
        } else {
            return err;
        }
    } else if (typeof msg === 'object') {
        if (msg.error_user_title) {
            return msg.error_user_title;
        } else if (msg.message) {
            return msg.message;
        } else if (msg.type) {
            return msg.type;
        } else if (msg.title) {
            return msg.title;
        } else if (msg.detail) {
            return msg.detail;
        } else {
            // return msg;
            return 'Unknown error';
        }
    } else {
        return 'Unknown error';
    }
};

/**
 * Finds the appropriate error message from the given error object
 * @param {object} err object containing IdP error message
 * @returns {string} specific error message
 */
export const getErrorMessageBody = (err) => {
    if (isString(err) && err.length > 0) {
        return err;
    } else if (typeof err === 'object') {
        if (err.error_user_msg) {
            return err.error_user_msg;
        } else if (err.message) {
            return err.message;
        } else if (err.type) {
            return err.type;
        } else if (err.detail) {
            return err.detail;
        } else if (err.title) {
            return err.title;
        } else {
            // return err;
            return 'Unknown error';
        }
    } else {
        return 'There was an unknown error processing this post.';
    }
};

// https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
/**
 * Format bytes as human-readable text.
 * @param {number} bytes Number of bytes.
 * @param {boolean} [si] True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param {number} [dp] Number of decimal places to display.
 * @returns {string} Formatted string.
 */
export const fileSizeDiplay = (bytes, si = false, dp = 1) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    // eslint-disable-next-line security/detect-object-injection
    return bytes.toFixed(dp) + ' ' + units[u];
}; // fileSizeDiplay

/**
 * Format a video length as a string: hh:mi:ss.mss
 * @param {number} lengthInSeconds total clip length in seconds
 * @returns {string|void} formated time string
 */
export const formatTimeLengthDisplay = (lengthInSeconds) => {
    if (!isNaN(lengthInSeconds) && !isNaN(parseFloat(lengthInSeconds))) {
        let ms = (lengthInSeconds % 1).toFixed(2).substring(2) || '00',
            ss = '00',
            mi = '00',
            hh = '00';

        lengthInSeconds = parseInt(lengthInSeconds, 10);

        ss = lengthInSeconds; // parseInt(lengthInSeconds, 10); // don't forget the second param
        hh = Math.floor(ss / 3600);
        // eslint-disable-next-line no-extra-parens
        mi = Math.floor((ss - (hh * 3600)) / 60);
        // eslint-disable-next-line no-extra-parens
        ss = ss - (hh * 3600) - (mi * 60);

        if (hh < 10) { hh = '0' + hh; }
        if (mi < 10) { mi = '0' + mi; }
        if (ss < 10) { ss = '0' + ss; }

        return `${hh}:${mi}:${ss}.${ms}`;
    } else {
        console.error('video length is not a number');
        return;
    }
} // formatTimeLengthDisplay
