import React from 'react';
import { useDrop } from 'react-dnd';
import { OverlayBackdrop } from './Calendar/OverlayBackdrop';

/**
 * Identity card containter page
 * @param {object} argv object defining properties to use in this function
 * @param {object} argv.identity identity profile
 * @param {Function} argv.onChangeOrder function to execute when order is changed
 * @param {boolean} argv.isFirst whether or not this is first
 * @param {boolean} argv.isLast whether or not this is last
 * @param {object} argv.children react child object
 * @returns {HTMLElement} html for identity card container block
 */
export function ClipContainer({
    identity,
    onChangeOrder,
    isFirst,
    isLast,
    children
}) {
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: ['Identity'],
        drop: (item) => { onChangeOrder(item, identity) },
        canDrop: () => { return true },
        collect: monitor => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
            didDrop: !!monitor.didDrop()
        })
    });

    return (
        <div ref={drop}>
            {isOver && canDrop && isFirst && <OverlayBackdrop color='green' /> &&
                <div>
                    <span>Move to Beginning</span>
                </div>
            }
            {children}
            {isOver && !canDrop && <OverlayBackdrop color='red' />}
            {isOver && canDrop && <OverlayBackdrop color='green' />}
            {isOver && canDrop && isLast && <OverlayBackdrop color='green' /> &&
                <div>
                    <span>Move to End</span>
                </div>
            }
        </div>
    );
}
