import React from 'react';
import { Button } from 'react-bootstrap';
import '../assets/styles/loaderButton.scss';

/**
 * Loader Button element
 * @param {object} argv object defining properties to use in this function
 * @param {boolean} argv.isLoading whether or not the page is currently loading
 * @param {string} argv.className class name style
 * @param {boolean} argv.disabled whether or not the button is disabled
 * @param {object} argv.props various properties
 * @returns {HTMLElement} html for loader button block
 */
export function LoaderButton({
    isLoading,
    className = '',
    disabled = false,
    ...props
}) {
    return (
        <Button
            className={`LoaderButton ${className}`}
            disabled={disabled || isLoading}
            {...props}
        >
            {/* {isLoading && <ArrowRepeat className='spinning' />} */}
            {isLoading && <div className='spinner-border spinner-border-sm text-light' role='status'><span className='sr-only'>Loading...</span></div>}
            &nbsp;{props.children}
        </Button>
    );
}
