import React from 'react';
import { Spinner } from 'react-bootstrap';

/**
 * Builds the UI for an overlay layer
 * @returns {HTMLElement} html
 */
export function OverlayMove() {

    return (
        <>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'absolute',
                height: '100%',
                width: '100%',
                zIndex: 1,
                opacity: 0.5,
                backgroundColor: 'white',
                top: 0,
                left: 0
            }} />
            <Spinner animation='border' role='status' size='lg' style={{
                zIndex: 5,
                position: 'absolute',
                top: '25%',
                left: '35%'
            }}>
                <span className='sr-only'>Loading...</span>
            </Spinner>
        </>
    );
}
