import React, { useContext, createContext } from 'react';

export const AppContext = createContext(null);

/**
 * Creates a React App Context
 * @returns {React.Context|null} a react context
 */
export function useAppContext() {
    return useContext(AppContext);
}