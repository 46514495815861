import React, { useState, useEffect, Suspense, ReactComponentElement } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartPie, faPencilAlt, faShieldAlt, faUserFriends } from '@fortawesome/free-solid-svg-icons';
// import { config } from '@fortawesome/fontawesome-svg-core';
import { getApplicationDetails } from '../../libs/Api/application';
import { updateIdentity, addPageCompanyIdentity } from '../../libs/Api/socialgroup';
import { Loading } from '../../../components/Loading';
import GridSystem from '../../components/GridSystem';
import { useToggle, useRouter } from '../../../assets/scripts/hooksLib';
import { formatDate, isAfterDate } from '../../../assets/scripts/dateLib';
import { nearestAncestorWithHref, adjustHeader } from '../../../assets/scripts/utilsLib';
import config from '../../../config';
// import { onError } from '../../../assets/scripts/errorLib';

const MetricSummary = React.lazy(() => import('../../components/Application/MetricSummary'));
const AddPageModal = React.lazy(() => import('../../components/Application/SocialGroup/Identities/AddPageModal'));
const SocialGroupRowEdit = React.lazy(() => import('../../components/Application/SocialGroup/Edit'));
const AddIdentityModal = React.lazy(() => import('../../components/Application/SocialGroup/Identities/Add'));
const ListItem = React.lazy(() => import('../../components/Application/SocialGroup/Identities/ListItem'));
const ErrorModal = React.lazy(() => import('../../components/ErrorMessage'));

/**
 * Application social group page
 * @returns {HTMLElement} html for social group block
 */
export function SocialGroup() {
    const router = useRouter();
    const [pageLoading, setPageLoading] = useState(true);
    const [isAddingIdentity, setIsAddingIdentity] = useState(false);
    const [identityIdClicked, setIdentityIdClicked] = useState(null);
    const [isRefreshingIdentity, setIsRefreshingIdentity] = useState(false);
    const [app, setApp] = useState(null);
    const [socialGroup, setSocialGroup] = useState(null);
    const [isEditing, setIsEditing] = useToggle(false);
    const [isLoading, setIsLoading] = useToggle(false);
    const [isListLoading, setIsListLoading] = useToggle(false);
    const [isAddingPage, setIsAddingPage] = useState(false);
    const [showSocialGroupIdentities, setShowSocialGroupIdentities] = useToggle(false);
    const handleShowSocialGroupIdentities = () => {
        setShowSocialGroupIdentities(true);
    };
    const handleCloseAddIdentity = () => setIsAddingIdentity(false);
    const handleCloseSocialGroupIdentities = () => { setSocialGroup(null); setShowSocialGroupIdentities(false); };
    const [pages, setPages] = useState(null);
    const [showPagesModal, setShowPagesModal] = useState(false);
    const handleClosePagesModal = () => { setShowPagesModal(false); setPages(null); setIdentity(null); };
    const [identity, setIdentity] = useState(null);
    const [isRefreshing, setIsRefreshing] = useToggle(false); // eslint-disable-line no-unused-vars
    const [errorMessage, setErrorMessage] = useState(null);
    const [showErrorModal, setShowErrorModal] = useState(false);

    /**
     * Sets the Error Modal Window with error message and context
     * @param {string} title error message window title
     * @param {string} message error messsage
     * @param {[string]} level (optional) level of severity, e.g. danger, warning, info, etc.
     * @param {[string]} buttonTxt (optional) text label on button
     * @returns {void}
     */
    const setErrorOccurred = (title, message, level='danger', buttonTxt='OK') => {
        setErrorMessage({
            title: title,
            message: message,
            level: level,
            buttonText: buttonTxt
        });
        setShowErrorModal(true);
    };

    // load application info
    useEffect(() => {
        let isMounted = true;

        const onLoad = async () => {
            try {
                adjustHeader();

                const { status, message, body } = await getApplicationDetails(router.query.id)
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.response && err.response.data ? err.response.data.message : err.message
                        };
                    });

                if (status !== 'OK') {
                    // onError(message);
                    setErrorOccurred('An Error Occurred Loading Application Info', message);
                } else {
                    setApp(body);

                    setSocialGroup(body.socialGroups.find(sg => {
                        return sg.id === router.query.grpId;
                    }));

                    // const socProfiles = body && body.socialGroups ? body.socialGroups.reduce((count, row) =>
                    //     count + (Array.isArray(row.socialGroupIdentitys) ? row.socialGroupIdentitys.length : 0), 0
                    // ) : 0;
                    // setIsWithinProfileLimit(body && socProfiles < (body.subscriptionProfileLimit === 0 ? 9999 : body.subscriptionProfileLimit));
                }
            } catch (e) {
                // console.error(e);
                if (e.response && e.response.status === 404) {
                    router.history('/apps');
                } else if (e === 'not authenticated' || e.response && e.response.status === 401) {
                    // router.history(`/login?redirect=${router.pathname}${router.query}`);
                    window.location.href = `../login.html?redirect=${router.pathname}${router.query}`;
                } else {
                    // onError(e);
                    setErrorOccurred('An Unexpected Error Occurred', e);
                }
            } finally {
                setPageLoading();
            }
        }

        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, [router, setApp, setSocialGroup, setPageLoading]);

    /**
     * Switch back and forth between read and write modes
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const toggleAddIdentity = (e) => {
        if (e) e.preventDefault();

        if (isAddingIdentity) {
            setIsAddingIdentity(false);
        } else {
            setIsAddingIdentity(true);
        }

        return;
    };

    /**
     * Shows or Hides the Social Group Identities modal
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const toggleShowSocialGroupIdentities = (e) => {
        if (e) e.preventDefault();

        if (showSocialGroupIdentities) {
            handleCloseSocialGroupIdentities();
        } else {
            const node = nearestAncestorWithHref(e.target),
                id = node && node.id;

            setSocialGroup(app.socialGroups.find(sg => {
                return sg.id === id;
            }));

            handleShowSocialGroupIdentities();
        }

        return;
    };

    /**
     * Switch back and forth between read and write modes
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const toggleGroupEditMode = (e) => {
        e.preventDefault();

        setIsEditing();

        return;
    };

    /**
     * Build the UI for the social group name
     * @returns {ReactComponentElement} html block
     */
    const renderSocialGroupInfo = () => {
        if (isEditing) {
            return (
                <Suspense fallback={<Loading pageLoading={true} />}>
                    <SocialGroupRowEdit
                        socialGroup={socialGroup}
                        setSocialGroup={setSocialGroup}
                        onCancel={toggleGroupEditMode}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        isEditingGroup={isEditing}
                        setIsEditingGroup={setIsEditing}
                        app={app}
                        setApp={setApp}
                    />
                </Suspense>
            );
        } else {
            return (
                <Row>
                    <Col onClick={toggleGroupEditMode} title='Social Group Name'>
                        <h2><FontAwesomeIcon icon={faUserFriends} />&nbsp;{socialGroup && socialGroup.name}&nbsp;<span className='smaller-x2'><FontAwesomeIcon icon={faPencilAlt} /></span></h2>
                    </Col>
                </Row>
            );
        }
    };

    /**
     * Build the UI for the add identity button
     * @returns {ReactComponentElement} html button
     */
    const addLink = () => {
        return (
            <Button
                variant='success'
                onClick={toggleAddIdentity}
                disabled={!app.isWithinProfileLimit}
            >
                <FontAwesomeIcon icon={faShieldAlt} />&nbsp;Add Identity
            </Button>
        );
    };

    /**
     * Save the list of pages, companies, or boards to the given identity
     * @param {string} identityId identity id
     * @param {Array<object>} list array of pages, companies, or boards objects
     * @returns {void}
     */
    const savePagesCompaniesBoardsList = async (identityId, list) => {
        const prevApp = { ...app };

        try {
            // get the indices of the respective group and identity
            const socialGroupIndex = app.socialGroups.findIndex(sg => {
                    return sg.id === socialGroup.id;
                }),
                identityIndex = app.socialGroups[`${socialGroupIndex}`].socialGroupIdentitys.findIndex(sgi => {
                    return sgi.id === identityId;
                });

            let identityLocal = {},
                grpId = app.socialGroups[`${socialGroupIndex}`].id;

            const socialGroupsLocal = [...app.socialGroups],
                socialGroupLocal = { ...socialGroupsLocal[`${socialGroupIndex}`] },
                socialGroupIdentitysLocal = [...socialGroupLocal.socialGroupIdentitys];

            identityLocal = { ...socialGroupIdentitysLocal[`${identityIndex}`], 'albumList': list };
            socialGroupIdentitysLocal[`${identityIndex}`] = identityLocal;
            socialGroupsLocal[`${socialGroupIndex}`].socialGroupIdentitys = socialGroupIdentitysLocal;

            // update the app's state
            setApp(app => {
                return { ...app, 'socialGroups': socialGroupsLocal }
            });

            const { status, message } = await updateIdentity(app.applicationId, grpId, identityLocal)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.response && err.response.data ? err.response.data.message : err.message
                    };
                });

            if (status !== 'OK') {
                setApp(prevApp);
                // onError(message);
                setErrorOccurred('An Error Occurred While Saving', message);
            }
        } catch (err) {
            setApp(prevApp);
            // onError(err);
            setErrorOccurred('An Unexpected Error Occurred', err);
        } finally {
            //
        }
    };

    /**
     * Adds the page or company to this social group
     * @param {string} grpId identity id
     * @param {object} identity identity to relate to the given page
     * @param {object} page page or company to add
     * @returns {void}
     */
    const savePageCompanyAsIdentity = async (grpId, identity, page) => {
        const prevApp = { ...app };

        try {
            const { status, message, body } = await addPageCompanyIdentity(app.applicationId, grpId, identity, page)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.response && err.response.data ? err.response.data.message : err.message
                    };
                });

            if (status !== 'OK') {
                setApp(prevApp);
                // onError(message);
                setErrorOccurred('An Error Occurred While Saving Identity', message);
            } else {
                // get the indices of the respective group and identity
                const socialGroupIndex = app.socialGroups.findIndex(sg => {
                        return sg.id === grpId;
                    }),
                    socialGroupsLocal = [...app.socialGroups],
                    socialGroupLocal = { ...socialGroupsLocal[`${socialGroupIndex}`] },
                    socialGroupIdentitysLocal = [...socialGroupLocal.socialGroupIdentitys],
                    existingIdentity = socialGroupIdentitysLocal.findIndex(i => { return i.identity === body.identity });

                // check for an existing identity, if one is found then replace it in the state
                if (existingIdentity > -1) {
                    socialGroupIdentitysLocal[`${existingIdentity}`] = body;
                } else {
                    socialGroupIdentitysLocal.push(body);
                }
                socialGroupsLocal[`${socialGroupIndex}`].socialGroupIdentitys = socialGroupIdentitysLocal;

                setApp(app => {
                    return { ...app, 'socialGroups': socialGroupsLocal }
                });
                setShowPagesModal(false);
            }
        } catch (err) {
            setApp(prevApp);
            // onError(err);
            setErrorOccurred('An Unexpected Error Occurred', err);
        }
    };

    /**
     * Helps sort social identities by applicationProviderId
     * @param {object} a the first object to compare
     * @param {object} b the second object to compare
     * @returns {number} reference to the original array
     */
    const sortIdentities = (a, b) => {
        if (a.applicationProviderId < b.applicationProviderId) {
            return -1;
        } else if (b.applicationProviderId < a.identity) {
            return 1;
        } else {
            return 0;
        }
    };

    /**
     * Checks if the given parent account has an expired token or not
     * @param {string} parentId the parent id to check
     * @returns {object} the parent name and whether or not it is expired
     */
    const isParentTokenExpired = (parentId) => {
        if (socialGroup && socialGroup.socialGroupIdentitys) {
            for (let i = 0; i < socialGroup.socialGroupIdentitys.length; i++) {
                // eslint-disable-next-line security/detect-object-injection
                let iden = socialGroup.socialGroupIdentitys[i];
                if (iden.id === parentId) {
                    // check if the access token's date has expired or not
                    if (isAfterDate(new Date(), iden.accessTokenExpirationUtc)) {
                        return {
                            parentName: iden.preferredUsername,
                            expired: true
                        };
                    }
                }
            }
        }
        return {
            expired: false
        };
    };

    /**
     * Switches the Error modal window visibility to true or false
     * @returns {void}
     */
    const showErrorMessageModal = () => {
        if (showErrorModal) {
            setShowErrorModal(false);
            setErrorMessage({});
        } else {
            setShowErrorModal(true);
        }
    };

    return (
        <div className='App'>
            {pageLoading && !app ?
                <Loading pageLoading={pageLoading} /> :
                <Container>
                    <Suspense fallback={<Loading pageLoading={true} />}>
                        <MetricSummary socialGroup={socialGroup} app={app} metricLevel={2} />
                    </Suspense>
                    <Suspense fallback={<Loading pageLoading={true} />}>
                        <AddIdentityModal
                            socialGroup={socialGroup}
                            setSocialGroup={setSocialGroup}
                            app={app}
                            setApp={setApp}
                            showSocialGroupIdentities={showSocialGroupIdentities}
                            setShowSocialGroupIdentities={setShowSocialGroupIdentities}
                            isWithinLimit={app.isWithinProfileLimit}
                            isAddingIdentity={isAddingIdentity}
                            setIsAddingIdentity={setIsAddingIdentity}
                            toggleShowSocialGroupIdentities={toggleShowSocialGroupIdentities}
                            handleCloseAddIdentity={handleCloseAddIdentity}
                            setPagesCompaniesBoards={savePagesCompaniesBoardsList}
                            isListLoading={isListLoading}
                            setIsListLoading={setIsListLoading}
                            isRefreshing={isRefreshing}
                            setShowPagesModal={setShowPagesModal}
                            setPages={setPages}
                            identity={identity}
                            setIdentity={setIdentity}
                            identityIdClicked={identityIdClicked}
                            setIdentityIdClicked={setIdentityIdClicked}
                        />
                    </Suspense>
                    <Suspense fallback={<Loading pageLoading={true} />}>
                        <AddPageModal
                            app={app}
                            socialGroup={socialGroup}
                            identity={identity}
                            pages={pages}
                            showPages={showPagesModal}
                            savePageOrCompanyAsIdentity={savePageCompanyAsIdentity}
                            handleClose={handleClosePagesModal}
                            isAddingPage={isAddingPage}
                            setIsAddingPage={setIsAddingPage}
                        />
                    </Suspense>
                    <Row className='padTop padBottom'>
                        <Col>
                            {renderSocialGroupInfo()}
                            <Row>
                                <Col>
                                    <span className='smaller-label'>Created by</span> <span className='smaller-field-value'>{socialGroup && socialGroup.addedBy && socialGroup.addedBy.Username ? socialGroup.addedBy.Username : socialGroup.addedBy}</span> <span className='smaller-label'>on</span> <span className='smaller-field-value'>{socialGroup && formatDate(socialGroup.addedAt, 'dd-MMM-yyyy HH:mm')}</span>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className='padTop padBottom usage-report-box'>
                        <Col>
                            <Row>
                                <Col>
                                    <h5><FontAwesomeIcon icon={faChartPie} />&nbsp;Group Usage Report</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    &nbsp;
                                </Col>
                                <Col>
                                    Drafts
                                </Col>
                                <Col>
                                    Daily Shares
                                </Col>
                                <Col>
                                    Monthly Shares
                                </Col>
                                <Col>
                                    Profiles
                                </Col>
                                <Col>
                                    Social Groups
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    Used
                                </Col>
                                <Col>
                                    {app.totalDrafts}
                                </Col>
                                <Col>
                                    {socialGroup.currentDayTotalShares}
                                </Col>
                                <Col>
                                    {socialGroup.currentMonthTotalShares}
                                </Col>
                                <Col>
                                    {socialGroup.socialGroupIdentitys ? socialGroup.socialGroupIdentitys.length : 0}
                                </Col>
                                <Col>
                                    {app.socialGroups.length}
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    App Limits
                                </Col>
                                <Col>
                                    -
                                </Col>
                                <Col>
                                    {app.subscriptionDailyShareLimit}
                                </Col>
                                <Col>
                                    {app.subscriptionMonthlyShareLimit}
                                </Col>
                                <Col>
                                    {app.subscriptionProfileLimit}
                                </Col>
                                <Col>
                                    {app.subscriptionSocialGroupLimit}
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className='padTop padBottom'>
                        <Col>
                            <Row>
                                <Col md={10} sm={9} title='Social Media Identity Profiles that can be used to share a post with'><h5><FontAwesomeIcon icon={faUserFriends} />&nbsp;Social Identities</h5></Col>
                                <Col md={2} sm={3} className='text-right'>
                                    {addLink()}
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Container as='ul' className='list-unstyled sortable ui-sortable'>
                                        <GridSystem colCount={3} md={4}>
                                            {socialGroup && socialGroup.socialGroupIdentitys && socialGroup.socialGroupIdentitys.sort(sortIdentities).map(m => {
                                                m.image = !m.image ? config.USERICON : m.image;

                                                // add access token to facebook private profile images paths
                                                if ([1, 29, 30].indexOf(m.providerId) > -1
                                                    && m.identityType === 'Person'
                                                    && m.image.indexOf('access_token') < 0) {
                                                    m.image = `${m.image}?access_token=${m.accessToken}`;
                                                }

                                                return (
                                                    <Suspense key={m.id} fallback={<Loading pageLoading={true} />}>
                                                        <ListItem
                                                            identity={m}
                                                            setIdentity={setIdentity}
                                                            app={app}
                                                            grpId={socialGroup.id}
                                                            setApp={setApp}
                                                            setSocialGroup={setSocialGroup}
                                                            setIsRefreshingIdentity={setIsRefreshingIdentity}
                                                            setPagesCompaniesBoards={savePagesCompaniesBoardsList}
                                                            isListLoading={isListLoading}
                                                            setIsListLoading={setIsListLoading}
                                                            isRefreshingIdentity={isRefreshingIdentity}
                                                            setShowPagesModal={setShowPagesModal}
                                                            setPages={setPages}
                                                            isParentTokenExpired={isParentTokenExpired}
                                                            setErrorMessage={setErrorMessage}
                                                            setShowErrorModal={setShowErrorModal}
                                                            layout='list'
                                                        />
                                                    </Suspense>
                                                );
                                            })}
                                        </GridSystem>
                                    </Container>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Suspense fallback={<Loading pageLoading={true} />}>
                        <ErrorModal message={errorMessage} show={showErrorModal} handleClose={showErrorMessageModal} />
                    </Suspense>
                </Container>
            }
        </div>
    );
}
