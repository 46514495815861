import { get, put, del } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';

/**
 * Retrieve a list of user objects from Cognito
 * @param {string} filter username filter
 * @param {string} [pageToken] pagination token
 * @returns {Promise} promise containing an array of user objects from Cognito
 */
export const getUsersList = async (filter, pageToken) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        let url = '/users';

        if (filter) {
            url = url + '?filter=name&value=' + filter;

            if (pageToken) {
                url = url + '&pageKey=' + pageToken;
            }
        } else {
            if (pageToken) {
                url = url + '?pageKey=' + pageToken;
            }
        }

        const { body } = await get({
            apiName: 'stitchz-web-api',
            path: url,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Gets a user object from Cognito
 * @param {string} username unique user id of the user object to return
 * @returns {Promise} promise containing the requested user object
 */
export const getUser = async (username) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await get({
            apiName: 'stitchz-web-api',
            path: `/user/${username}`,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Enables or Disables a user object in Cognito
 * @param {string} username unique user id of the user object to return
 * @param {boolean} enableOrDisable true to enable or false to disable
 * @returns {Promise} promise containing the requested user object
 */
export const enableDisableUser = async (username, enableOrDisable) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        let url = `/user/${username}/${enableOrDisable ? 'enable' : 'disable'}`;

        const { body } = await put({
            apiName: 'stitchz-web-api',
            path: url,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Confirms a user object in Cognito
 * @param {string} username unique user id of the user object to return
 * @returns {Promise} promise containing the requested user object
 */
export const confirmUser = async (username) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await put({
            apiName: 'stitchz-web-api',
            path: `/user/${username}/confirm`,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Administrative delete of a user object from Cognito
 * @param {string} username unique user id of the user object to return
 * @returns {Promise} promise containing the requested user object
 */
export const removeUser = async (username) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { statusCode } = await del({
            apiName: 'stitchz-web-api',
            path: `/user/${username}`,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return statusCode;
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Deletes a user object from Cognito
 * @returns {Promise} promise containing the requested user object
 */
export const getMyAccount = async () => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await get({
            apiName: 'stitchz-web-api',
            path: `/me/${user.username}`,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Deletes a user object from Cognito
 * @returns {Promise} promise containing the requested user object
 */
export const removeMyAccount = async () => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { statusCode } = await del({
            apiName: 'stitchz-web-api',
            path: `/me/${user.username}`,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return statusCode;
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};
