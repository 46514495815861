import React from 'react';
import { Spinner } from 'react-bootstrap';

/**
 * Loading page
 * @param {object} argv object defining properties to use in this function
 * @param {boolean} argv.pageLoading whether or not the page is currently loading
 * @returns {HTMLElement} html for loading block
 */
export function Loading({
    pageLoading
}) {
    return (
        pageLoading &&
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Spinner animation='border' role='status' size='lg'>
                <span className='sr-only'>Loading...</span>
            </Spinner>
        </div>
    );
}
