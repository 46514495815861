import { get, put, post, del } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';

/**
 * Begins the request to add a new Identity Profile to this Social Group
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {object} requestBody identity request object, including state, email and provider Id
 * @returns {Promise} http message response promise
 */
export const initiateNewIdentityRequest = async (appId, socialGroupId, requestBody) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await post({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/social/${socialGroupId}/identity`,
            options: {
                headers: {
                    Authorization: token
                },
                body: requestBody
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Saves the social group to this application to the database
 * @param {string} appId application id
 * @param {object} group social group object
 * @returns {Promise} http message response promise
 */
export const saveSocialGroup = async (appId, group) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await put({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/social`,
            options: {
                headers: {
                    Authorization: token
                },
                body: {
                    id: group.id, // this will be null in new groups
                    name: group.name
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Removes the given social group from the application and updates the database
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @returns {Promise} http message response promise
 */
export const removeSocialGroup = async (appId, socialGroupId) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { statusCode } = await del({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/social/${socialGroupId}`,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return statusCode;
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Update the given identity and saves to the database
 * @param {string} appId application id
 * @param {string} grpId social group id
 * @param {object} identity identity to update
 * @returns {Promise} http message response promise
 */
export const updateIdentity = async (appId, grpId, identity) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await put({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/social/${grpId}/identity`,
            options: {
                headers: {
                    Authorization: token
                },
                body: { ...identity }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Adds the given page and saves to the database
 * @param {string} appId application id
 * @param {string} grpId social group id
 * @param {object} identity identity to relate the page to
 * @param {object} page page identity properties
 * @returns {Promise} http message response promise
 */
export const addPageCompanyIdentity = async (appId, grpId, identity, page) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await post({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/social/${grpId}/identity/${identity.id}/pages`,
            options: {
                headers: {
                    Authorization: token
                },
                body: {
                    identity: identity,
                    page: page
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Remove the Identity Profile from this Social Group
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} identityId unique id of the identity to remove
 * @returns {Promise} http message response promise
 */
export const removeIdentity = async (appId, socialGroupId, identityId) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { statusCode } = await del({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/social/${socialGroupId}/identity/${identityId}`,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return statusCode;
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Gets a list of Pages, Companies, or Boards associated with the Identity Profile
 * @param {string} appId application id
 * @param {string} socialGroupId social group id
 * @param {string} identityId unique id of the identity
 * @param {number} providerId provider id of identity
 * @returns {Promise} http message response promise
 */
export const getPagesCompaniesBoards = async (appId, socialGroupId, identityId, providerId) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        let url;

        if (providerId === 1 || providerId === 29) {
            url = `/app/${appId}/social/${socialGroupId}/identity/${identityId}/pages`;
        } else if (providerId === 5) {
            url = `/app/${appId}/social/${socialGroupId}/identity/${identityId}/pages`;
        } else if (providerId === 27) {
            url = `/app/${appId}/social/${socialGroupId}/identity/${identityId}/boards`;
        }

        if (!url) {
            return Promise.reject('This provider is not allowed to request pages.');
        }

        const { body } = await get({
            apiName: 'stitchz-web-api',
            path: url,
            options: {
                headers: {
                    Authorization: token
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};

/**
 * Uploads a binary video clip
 * @param {string} appId application id
 * @param {string} socialGroupId socialGroup id
 * @param {object} sourceIdentityId identity id of the identity to move
 * @param {string} destinationIdentityId identity id of the identity to replace
 * @returns {Promise} http message response promise
 */
export const reorderIdentitys = async (appId, socialGroupId, sourceIdentityId, destinationIdentityId) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        const { body } = await post({
            apiName: 'stitchz-web-api',
            path: `/app/${appId}/social/${socialGroupId}/identity/reorder`,
            options: {
                headers: {
                    Authorization: token
                },
                body: {
                    sourceIdentityId: sourceIdentityId,
                    destinationIdentityId: destinationIdentityId
                }
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};
