import React, { ReactComponentElement } from 'react';
import { Container, Row, Col, Button, Tabs, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faList } from '@fortawesome/free-solid-svg-icons';
import { Icon } from './Icon';
import { getLocalDateTime, formatDate, parseDateTime } from '../../../assets/scripts/dateLib';
import { Loading } from '../../../components/Loading';
import { LoaderButton } from '../../../components/LoaderButton';
import config from '../../../config';
import '../../../assets/styles/social.scss';

/**
 * Builds the current date's calendar items for the week in scope
 * @param {object} argv object defining properties to use in this function
 * @param {Array} argv.posts collection of posts for this week
 * @param {object} argv.socialGroup social group associated with these posts
 * @param {boolean} argv.pageLoading whether or not the master page is loading
 * @param {boolean} argv.isLoading whether or not the function is running
 * @param {Function} argv.onClickCreatePost function to trigger a post creation
 * @param {Function} argv.onClickDeletePost function to control the deletion of the post
 * @param {Function} argv.onClickCopy function to handle copying of the post
 * @param {boolean} argv.isAllowedToPost whether or not this application is allowed to post a share
 * @param {object} argv.listTab ...
 * @param {object} argv.setListTab ...
 * @param {object} argv.router routing object history
 * @returns {HTMLElement} html
 */
export function PostsList({
    posts,
    socialGroup,
    pageLoading,
    isLoading,
    onClickCreatePost,
    onClickDeletePost,
    onClickCopy, // eslint-disable-line no-unused-vars
    isAllowedToPost,
    listTab,
    setListTab,
    router
}) {

    /**
     * Returns the identity object associated with the post
     * @param {string} id unique id for the identity account
     * @returns {object} identity object
     */
    const getIdentity = (id) => {
        return socialGroup && socialGroup.socialGroupIdentitys && socialGroup.socialGroupIdentitys.find(s => {
            return s.id === id;
        });
    };

    /**
     * Adds a button and disables it if there are no edit permissions
     * @returns {ReactComponentElement} button element
     */
    const allowedToPost = () => {
        if (isAllowedToPost) {
            return (
                <LoaderButton
                    variant='primary'
                    isLoading={isLoading}
                    onClick={onClickCreatePost}
                >
                    Create Post
                </LoaderButton>
            );
        } else {
            return (
                <Button variant='secondary' disabled>Create Post</Button>
            );
        }
    };

    /**
     * Sets the current tab & navigation based on the selection
     * @param {MouseEvent} e mouse click
     * @returns {void}
     */
    const onTabSelect = (e) => {
        // TODO: filter posts by tab topic

        setListTab(e);
    };

    /**
     * Build and displays the posts lists UI
     * @param {Array} posts collection of posts
     * @returns {HTMLElement} html list of posts
     */
    const renderPostList = (posts) => {
        let list = [],
            postsStatus = 'Posted';

        if (posts && Array.isArray(posts)) {
            list = list.concat(posts);
        }

        // filter by the tab name
        if (listTab) {
            list = list.filter(p => p.postStatus === config.postStatus[`${listTab}`]);

            try {
                // sort by most recent post first
                if (listTab === 'POSTED') {
                    list = list.sort((a, b) => parseDateTime(a.postDate) - parseDateTime(b.postDate));
                    postsStatus = 'Posted';
                } else if (listTab === 'SCHEDULED') {
                    list = list.sort((a, b) => parseDateTime(a.scheduledPostDate) - parseDateTime(b.scheduledPostDate));
                    postsStatus = 'Scheduled';
                } else { // sort the array by their create date
                    list = list.sort((a, b) => parseDateTime(a.addedAt) - parseDateTime(b.addedAt));
                    postsStatus = 'Drafts';
                }
            } catch (err) {
                console.error(err);
            }
        }

        const totalPosts = list ? list.length : 0;

        return (
            <div className='border-border'>
                <div className='box-header'>
                    <Row>
                        <Col>
                            {totalPosts} {postsStatus} in {postsStatus === 'Scheduled' ? 'next' : 'last'} 30 days
                        </Col>
                    </Row>
                </div>
                <div className='box-content'>
                    <Row className='social-posts-list'>
                        <Col>
                            <Tabs
                                id='controlled-tab-list'
                                activeKey={listTab}
                                onSelect={onTabSelect}
                                variant='pills'
                                className='d-flex justify-content-center'
                            >
                                <Tab eventKey='POSTED' title='Posted'>
                                    {
                                        (!list || list.length === 0) &&
                                        <Row>
                                            <Col className='center'>
                                                <div>No posts yet....how about creating one?</div>
                                                {allowedToPost()}
                                            </Col>
                                        </Row>
                                    }
                                    {list && list.map(m => {
                                        if (m.postStatus !== config.postStatus.ERRORED) {
                                            const iden = getIdentity(m.identityId);

                                            if (iden) {
                                                const provider = iden && config.providers.find(p => {
                                                        return p.id === iden.providerId;
                                                    }),
                                                    repins = iden.providerId === 27 ? <Row>
                                                        <Col>
                                                            <div className='engagement-count'>{m.repins}</div>
                                                            <div>Re-Pins</div>
                                                        </Col>
                                                    </Row> : ''
                                                ;

                                                return (
                                                    <Row key={`${m.postId}-${m.socialPostCode}`} className='social-post'>
                                                        <div className='social-post-background'>
                                                            <Icon name={provider.iconName} />
                                                        </div>
                                                        <Col>
                                                            <Row className='social-post-body'>
                                                                <Col md={1} className='d-sm-none d-md-block'>
                                                                    <Row>
                                                                        <Col>
                                                                            <img src={iden.image} height='75' width='75' alt='social identity' />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <div>
                                                                                <Button variant='info' href={`/${router.query.id}/social/${router.query.grpId}/post/${m.postId}`}>Edit</Button>
                                                                            </div>
                                                                            <div>
                                                                                <Button variant='secondary' id={m.socialPostCode} href={`/${router.query.id}/social/${router.query.grpId}/post/master/${m.socialPostCode}`}>Edit Master</Button>
                                                                            </div>
                                                                            <div>
                                                                                <Button variant='warning'>Repost</Button>
                                                                            </div>
                                                                            <div>
                                                                                <Button variant='danger' id={m.postId} onClick={onClickDeletePost}>Delete</Button>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col sm={9} md={7}>
                                                                    <div>{iden.displayName}</div>
                                                                    <div>{m.message}</div>
                                                                    {m.media && m.media.url &&
                                                                        <div>
                                                                            <img src={m.media.url} width='150' height='150' alt={m.mediaUrl} title={m.title} className='img-thumbnail' />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        m.shareUrl &&
                                                                        <div>
                                                                            <a href={m.shareUrl} target='_blank' rel='noopener noreferrer'>{m.shareUrl}</a>
                                                                        </div>
                                                                    }
                                                                </Col>
                                                                <Col sm={2} md={2}>
                                                                    <Row>
                                                                        <Col>
                                                                            <div className='engagement-count'>{m.likes}</div>
                                                                            <div>Likes</div>
                                                                        </Col>
                                                                    </Row>
                                                                    {repins}
                                                                </Col>
                                                                <Col sm={2} md={2}>
                                                                    <Row>
                                                                        <Col>
                                                                            <div className='engagement-count'>{m.comments}</div>
                                                                            <div>Comments</div>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            <Row className='social-stats-bar'>
                                                                <Col sm='auto' md='auto' className='text-smaller'>
                                                                    <div className='date-posted' title='scheduled post date'>
                                                                        {m.postStatus === config.postStatus.POSTED ?
                                                                            <>
                                                                                Posted on <FontAwesomeIcon icon={faClock} /> {formatDate(getLocalDateTime(m.postDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"), 'dd-MMM-yyyy HH:mm')} {/* eslint-disable-line quotes */}
                                                                            </>
                                                                            :
                                                                            <>
                                                                                Scheduled for <FontAwesomeIcon icon={faClock} /> {formatDate(getLocalDateTime(m.scheduledPostDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"), 'dd-MMM-yyyy HH:mm')} {/* eslint-disable-line quotes */}
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </Col>
                                                                <Col sm='auto' md='auto' className='text-smaller' title='date created'>
                                                                    <div>Created on <FontAwesomeIcon icon={faClock} /> {formatDate(m.addedAt, 'dd-MMM-yyyy HH:mm')}</div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                );
                                            } else {
                                                // console.log(`${m.postId} is missing or invalid identity!!!!!`);
                                                // return  (
                                                //     <Row key={m.postId}>
                                                //         <Col>
                                                //             <span>No posts found....how about creating one?</span>
                                                //         </Col>
                                                //     </Row>
                                                // );
                                                return (
                                                    <Row key={`${m.postId}-${m.socialPostCode}`} className='orphaned-social-post'>
                                                        <div className='orphaned-social-post-background'>
                                                            &nbsp;
                                                        </div>
                                                        <Col sm={8}>
                                                            <Row>
                                                                <Col>
                                                                    [Oprhaned Post]: {m.postId}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    {m.title}
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col>
                                                                    Created on <FontAwesomeIcon icon={faClock} /> {formatDate(m.addedAt, 'dd-MMM-yyyy HH:mm')}
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col sm={4}>
                                                            <div className='float-right'>
                                                                <Button variant='danger' id={m.postId} onClick={onClickDeletePost}>Delete</Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                );
                                            }
                                        } else {
                                            return '';
                                        }
                                    })}
                                </Tab>
                                <Tab eventKey='DRAFT' title='Drafts'>
                                    {list && list.map(m => {
                                        return (
                                            <Row key={`${m.postId}-${m.socialPostCode}`} className='social-post'>
                                                <Col>
                                                    <Row className='social-post-body'>
                                                        <Col md={1} className='d-sm-none d-md-block'>
                                                            <Row>
                                                                <Col>
                                                                    <div>
                                                                        <Button variant='info' href={`/${router.query.id}/social/${router.query.grpId}/post/master/${m.socialPostCode}`}>Edit</Button>
                                                                    </div>
                                                                    <div>
                                                                        <Button variant='danger' id={m.postId} onClick={onClickDeletePost}>Delete</Button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col sm={9} md={7}>
                                                            <div>{m.message || 'DRAFT MESSAGE'}</div>
                                                            {m.media && m.media.url &&
                                                                <div>
                                                                    <img src={m.media && m.media.url} width='150' height='150' className='img-thumbnail' alt='placeholder' />
                                                                </div>
                                                            }
                                                            {
                                                                m.shareUrl &&
                                                                <div>
                                                                    <a href={m.shareUrl} target='_blank' rel='noopener noreferrer'>{m.shareUrl}</a>
                                                                </div>
                                                            }
                                                        </Col>
                                                    </Row>
                                                    <Row className='social-stats-bar'>
                                                        {
                                                            m.scheduledPostDate &&
                                                            <Col sm='auto' md='auto' className='text-smaller'>
                                                                <div className='date-posted' title='scheduled post date'>
                                                                    Scheduled for <FontAwesomeIcon icon={faClock} /> <time>{formatDate(m.scheduledPostDate, 'dd-MMM-yyyy HH:mm')}</time>
                                                                </div>
                                                            </Col>
                                                        }
                                                        <Col sm='auto' md='auto' className='text-smaller' title='date created'>
                                                            <div>Created on <FontAwesomeIcon icon={faClock} /> {formatDate(m.addedAt, 'dd-MMM-yyyy HH:mm')}</div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        );
                                    })}
                                </Tab>
                                <Tab eventKey='SCHEDULED' title='Scheduled'>
                                    {
                                        (!list || list.length === 0) &&
                                        <Row>
                                            <Col className='center'>
                                                <div>No posts yet....how about creating one?</div>
                                                {allowedToPost()}
                                            </Col>
                                        </Row>
                                    }
                                    {list && list.map(m => {
                                        if (m.postStatus !== config.postStatus.ERRORED) {
                                            const iden = getIdentity(m.identityId);

                                            if (iden) {
                                                const provider = iden && config.providers.find(p => {
                                                        return p.id === iden.providerId;
                                                    }),
                                                    repins = iden.providerId === 27 ? // eslint-disable-line no-unused-vars
                                                        <Row>
                                                            <Col>
                                                                <div className='engagement-count'>{m.repins}</div>
                                                                <div>Re-Pins</div>
                                                            </Col>
                                                        </Row> : ''
                                                    ;

                                                return (
                                                    <Row key={`${m.postId}-${m.socialPostCode}`} className='social-post'>
                                                        <div className='social-post-background'>
                                                            <Icon name={provider.iconName} />
                                                        </div>
                                                        <Col>
                                                            <Row className='social-post-body'>
                                                                <Col md={1} className='d-sm-none d-md-block'>
                                                                    <Row>
                                                                        <Col>
                                                                            <img src={iden.image} height='75' width='75' alt='social identity' />
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col>
                                                                            <div>
                                                                                <Button variant='info' href={`/${router.query.id}/social/${router.query.grpId}/post/${m.postId}`}>Edit</Button>
                                                                            </div>
                                                                            <div>
                                                                                <Button variant='secondary' id={m.socialPostCode} href={`/${router.query.id}/social/${router.query.grpId}/post/master/${m.socialPostCode}`}>Edit Master</Button>
                                                                            </div>
                                                                            <div>
                                                                                <Button variant='warning'>Repost</Button>
                                                                            </div>
                                                                            <div>
                                                                                <Button variant='danger' id={m.postId} onClick={onClickDeletePost}>Delete</Button>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                <Col sm={9} md={7}>
                                                                    <div>{iden.displayName}</div>
                                                                    <div>{m.message}</div>
                                                                    {m.media && m.media.url &&
                                                                        <div>
                                                                            <img src={m.media.url} width='150' height='150' alt={m.mediaUrl} title={m.title} className='img-thumbnail' />
                                                                        </div>
                                                                    }
                                                                    {
                                                                        m.shareUrl &&
                                                                        <div>
                                                                            <a href={m.shareUrl} target='_blank' rel='noopener noreferrer'>{m.shareUrl}</a>
                                                                        </div>
                                                                    }
                                                                </Col>
                                                                <Col sm={4} md={4}>
                                                                    &nbsp;
                                                                </Col>
                                                            </Row>
                                                            <Row className='social-stats-bar'>
                                                                <Col sm='auto' md='auto' className='text-smaller'>
                                                                    <div className='date-posted' title='scheduled post date'>
                                                                        {m.postStatus === config.postStatus.POSTED ?
                                                                            <>
                                                                                Posted on <FontAwesomeIcon icon={faClock} /> {formatDate(getLocalDateTime(m.postDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"), 'dd-MMM-yyyy HH:mm')} {/* eslint-disable-line quotes */}
                                                                            </>
                                                                            :
                                                                            <>
                                                                                Scheduled for <FontAwesomeIcon icon={faClock} /> {formatDate(getLocalDateTime(m.scheduledPostDate, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'"), 'dd-MMM-yyyy HH:mm')} {/* eslint-disable-line quotes */}
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </Col>
                                                                <Col sm='auto' md='auto' className='text-smaller' title='date created'>
                                                                    <div>Created on <FontAwesomeIcon icon={faClock} /> {formatDate(m.addedAt, 'dd-MMM-yyyy HH:mm')}</div>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                );
                                            } else {
                                                // console.log(`${m.postId} is missing or invalid identity!!!!!`);
                                                // return  (
                                                //     <Row key={m.postId}>
                                                //         <Col>
                                                //             <span>No posts found....how about creating one?</span>
                                                //         </Col>
                                                //     </Row>
                                                // );
                                                return '';
                                            }
                                        } else {
                                            return '';
                                        }
                                    })}
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

    return (
        pageLoading ?
            <Loading pageLoading={pageLoading} /> :
            <Container>
                <Row>
                    <Col sm={12} md={10}>
                        <div className='page-header'>
                            <h1><FontAwesomeIcon icon={faList} />&nbsp;Social Sharing</h1>
                        </div>
                    </Col>
                    <Col sm={12} md={2}>
                        {allowedToPost()}
                    </Col>
                </Row>

                {renderPostList(posts)}
            </Container>
    );
}
