import config from '../../../config';

export const amplifyConfig = async () => {
    return {
        Auth: {
            Cognito: {
                // allowGuestAccess: true,
                region: config.cognito.REGION,
                userPoolId: config.cognito.USER_POOL_ID,
                identityPoolId: config.cognito.IDENTITY_POOL_ID,
                userPoolClientId: config.cognito.APP_CLIENT_ID,

                // mandatorySignIn: true,

                // signUpVerificationMethod: 'code',
                loginWith: {
                    oauth: {
                        domain: 'stitchztest2',
                        // redirectSignIn: ['https://localhost:3000/'],
                        // redirectSignOut: ['https://localhost:3000/'],
                        // responseType: 'code',
                        scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile']
                    },
                    username: 'true',
                    email: 'false' // Optional
                }
            }
        },
        Storage: {
            S3: {
                region: config.s3.REGION,
                bucket: config.s3.BUCKET,
                identityPoolId: config.cognito.IDENTITY_POOL_ID
            }
        },
        API: {
            REST: {
                'stitchz-web-api': {
                    endpoint: config.apiGateway.URL,
                    region: config.apiGateway.REGION
                }
            }
        }
    };
};