import React, { useState, useEffect, Suspense, ReactComponentElement } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { getApplicationDetails } from '../../libs/Api/application';
import { removeSocialGroup, saveSocialGroup, updateIdentity, addPageCompanyIdentity } from '../../libs/Api/socialgroup';
import { Loading } from '../../../components/Loading';
import { LoaderButton } from '../../../components/LoaderButton';
import { useToggle, useRouter } from '../../../assets/scripts/hooksLib';
import { nearestAncestorWithHref, adjustHeader } from '../../../assets/scripts/utilsLib';
import { onError } from '../../../assets/scripts/errorLib';

const MetricSummary = React.lazy(() => import('../../components/Application/MetricSummary'));
const AddPageModal = React.lazy(() => import('../../components/Application/SocialGroup/Identities/AddPageModal'));
const SocialGroupRowEdit = React.lazy(() => import('../../components/Application/SocialGroup/Edit'));
const AddIdentityModal = React.lazy(() => import('../../components/Application/SocialGroup/Identities/Add'));
const SocialGroupRow = React.lazy(() => import('../../components/Application/SocialGroup/Row'));
const AddSocialGroupForm = React.lazy(() => import('../../components/Application/SocialGroup/New'));

/**
 * Application social group list page
 * @returns {HTMLElement} html for social group list block
 */
export function SocialGroups() {
    const router = useRouter();
    const [pageLoading, setPageLoading] = useState(true);
    const [app, setApp] = useState(null);
    const [socialGroup, setSocialGroup] = useState({ name: '' });
    const [isAddingIdentity, setIsAddingIdentity] = useToggle(false);
    const [identityIdClicked, setIdentityIdClicked] = useState(null);
    const handleCloseAddIdentity = () => setIsAddingIdentity(false);
    const [showSocialGroupIdentities, setShowSocialGroupIdentities] = useToggle(false);
    const handleCloseSocialGroupIdentities = () => { setSocialGroup({ name: '' }); setShowSocialGroupIdentities(false); }
    const handleShowSocialGroupIdentities = () => {
        setShowSocialGroupIdentities(true);
    };
    // const [isWithinProfileLimit, setIsWithinProfileLimit] = useState(false);
    // const [isWithinGroupsLimit, setIsWithinGroupsLimit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isEditingGroup, setIsEditingGroup] = useState(false);
    const [isAddingPage, setIsAddingPage] = useState(false);
    const [canEdit, setCanEdit] = useToggle(false);
    const [isAddingGroup, setIsAddingGroup] = useToggle(false);
    const [isListLoading, setIsListLoading] = useToggle(false);
    const [pages, setPages] = useState(null);
    const [showPagesModal, setShowPagesModal] = useState(false);
    const handleClosePagesModal = () => { setShowPagesModal(false); setPages(null); setIdentity(null); };
    const [identity, setIdentity] = useState(null);
    const [isRefreshing, setIsRefreshing] = useToggle(false); // eslint-disable-line no-unused-vars

    /**
     * @typedef {{
     * onkeyup: number
     * }} KeyEvent
     */

    // load application info
    useEffect(() => {
        let isMounted = true;

        const onLoad = async () => {
            try {
                adjustHeader();

                const { status, message, body } = await getApplicationDetails(router.query.id)
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.response && err.response.data ? err.response.data.message : err.message
                        };
                    });

                if (status !== 'OK') {
                    onError(message);
                } else {
                    setApp(body);
                    setCanEdit(body.Enabled);
                }
            } catch (e) {
                // console.error(e);
                if (e.response && e.response.status === 404) {
                    router.history('/apps');
                } else if (e === 'not authenticated' || e.response && e.response.status === 401) {
                    // router.history(`/login?redirect=${router.pathname}${router.query}`);
                    window.location.href = `../login.html?redirect=${router.pathname}${router.query}`;
                } else {
                    onError(e);
                }
            } finally {
                setPageLoading(false);
            }
        }

        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, [router, setApp, setPageLoading, setCanEdit]);

    /**
     * Returns true if the socialGroup string is longer than 3 characters
     * @returns {boolean} true or false
     */
    const validate = () => {
        return socialGroup && socialGroup.name && socialGroup.name.length > 3;
    };

    /**
     * onChange event for the text input for the new social group name
     * @param {KeyEvent} e key press event
     * @returns {void}
     */
    const onChange = (e) => {
        const val = e.target.value,
            field = e.target.id;

        setSocialGroup(socialGroup => {
            return { ...socialGroup, [field]: val }
        });
    };

    /**
     * onClick mouse event to add the new social group to the application
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const onSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        try {
            const { status, message, body } = await saveSocialGroup(app.applicationId, socialGroup)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.response && err.response.data ? err.response.data.message : err.message
                    };
                });

            if (status !== 'OK') {
                onError(message);
            } else {
                setApp(body);
                // setIsWithinGroupsLimit(body && body.socialGroups ? body.socialGroups.length < (body.subscriptionSocialGroupLimit === 0 ? 9999 : body.subscriptionSocialGroupLimit) : false);
                setSocialGroup({ name: '' });
                setIsAddingGroup(false);
            }
        } catch (err) {
            onError(err);
        } finally {
            setIsLoading(false);
        }
    };

    /**
     * Switch back and forth between read and write modes
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const toggleGroupAddMode = (e) => {
        e.preventDefault();

        // disabled editing
        if (!canEdit) {
            setIsAddingGroup(false);
            return;
        }

        if (isEditingGroup) { setIsEditingGroup(false); setSocialGroup({}); }
        isAddingGroup ? setIsAddingGroup(false) : setIsAddingGroup(true);
        return;
    };

    /**
     * Switch back and forth between read and write modes
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const toggleGroupEditMode = (e) => {
        e.preventDefault();

        // disable editing
        if (!canEdit) {
            setIsEditingGroup(false);
            return;
        }

        if (isAddingGroup) { setIsAddingGroup(false); setSocialGroup({}); }

        const node = nearestAncestorWithHref(e.target),
            id = node && node.id;

        if (isEditingGroup === id) {
            setIsEditingGroup(false);
            setSocialGroup({ name: '' });
        } else {
            setIsEditingGroup(id);
            setSocialGroup(app.socialGroups.find(sg => {
                return sg.id === id;
            }));
        }

        return;
    };

    /**
     * onClick mouse event to remove the new social group from the application and updates the state
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const onClickRemoveSocialGroup = async (e) => {
        e.preventDefault();

        // disable editing
        if (!canEdit) {
            return;
        }

        const node = nearestAncestorWithHref(e.target),
            id = node && node.id,
            approved = window.confirm('Do you wish to remove this Social Group and ALL its settings & content?\n\nThis is not recoverable!');

        if (approved) {
            setIsLoading(true);

            try {
                const { status, message, body } = await removeSocialGroup(app.applicationId, id)
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.response && err.response.data ? err.response.data.message : err.message
                        };
                    });

                if (status !== 'OK') {
                    onError(message);
                } else {
                    setApp(body);
                    // setCanEdit(body.Enabled);
                    // setIsWithinGroupsLimit(body && body.socialGroups ? body.socialGroups.length < (body.subscriptionSocialGroupLimit === 0 ? 9999 : body.subscriptionSocialGroupLimit) : false);
                }
            } catch (err) {
                onError(err);
            } finally {
                setIsLoading(false);
            }
        }
    };

    /**
     * Shows or Hides the Social Group Identities modal
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const toggleShowSocialGroupIdentities = (e) => {
        if (e) e.preventDefault();

        if (showSocialGroupIdentities) {
            handleCloseSocialGroupIdentities();
        } else {
            const node = nearestAncestorWithHref(e.target),
                id = node && node.id;

            setSocialGroup(app.socialGroups.find(sg => {
                return sg.id === id;
            }));

            handleShowSocialGroupIdentities();
        }

        return;
    };

    /**
     * Display the Html button to create a group
     * @returns {ReactComponentElement} html button
     */
    const addNewGroupButton = () => {
        if (app.isWithinGroupLimit) {
            return (
                <LoaderButton
                    variant='primary'
                    isLoading={isLoading}
                    onClick={toggleGroupAddMode}
                >
                    Create Group
                </LoaderButton>
            );
        } else {
            return (
                <LoaderButton
                    variant='secondary'
                    isLoading={isLoading}
                    disabled
                >
                    Create Group
                </LoaderButton>
            );
        }
    };

    /**
     * Save the list of pages, companies, or boards to the given identity
     * @param {string} identityId identity id
     * @param {Array<object>} list array of pages, companies, or boards objects
     * @returns {void}
     */
    const savePagesCompaniesBoardsList = async (identityId, list) => {
        const prevApp = { ...app };

        try {
            // get the indices of the respective group and identity
            const socialGroupIndex = app.socialGroups.findIndex(sg => {
                    return sg.id === socialGroup.id;
                }),
                identityIndex = app.socialGroups[`${socialGroupIndex}`].socialGroupIdentitys.findIndex(sgi => {
                    return sgi.id === identityId;
                });

            let identityLocal = {},
                grpId = app.socialGroups[`${socialGroupIndex}`].id;

            const socialGroupsLocal = [...app.socialGroups],
                socialGroupLocal = { ...socialGroupsLocal[`${socialGroupIndex}`] },
                socialGroupIdentitysLocal = [...socialGroupLocal.socialGroupIdentitys];

            identityLocal = { ...socialGroupIdentitysLocal[`${identityIndex}`], 'albumList': list };
            socialGroupIdentitysLocal[`${identityIndex}`] = identityLocal;
            socialGroupsLocal[`${socialGroupIndex}`].socialGroupIdentitys = socialGroupIdentitysLocal;

            // update the app's state
            setApp(app => {
                return { ...app, 'socialGroups': socialGroupsLocal }
            });

            const { status, message } = await updateIdentity(app.applicationId, grpId, identityLocal)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.response && err.response.data ? err.response.data.message : err.message
                    };
                });

            if (status !== 'OK') {
                setApp(prevApp);
                onError(message);
            }
        } catch (err) {
            setApp(prevApp);
            onError(err);
        } finally {
            //
        }
    };

    /**
     * Adds the page or company to this social group
     * @param {string} grpId identity id
     * @param {object} identity identity to relate to the given page
     * @param {object} page page or company to add
     * @returns {void}
     */
    const savePageCompanyAsIdentity = async (grpId, identity, page) => {
        const prevApp = { ...app };

        try {
            const { status, message, body } = await addPageCompanyIdentity(app.applicationId, grpId, identity, page)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.response && err.response.data ? err.response.data.message : err.message
                    };
                });

            if (status !== 'OK') {
                setApp(prevApp);
                onError(message);
            } else {
                // get the indices of the respective group and identity
                const socialGroupIndex = app.socialGroups.findIndex(sg => {
                        return sg.id === grpId;
                    }),
                    socialGroupsLocal = [...app.socialGroups],
                    socialGroupLocal = { ...socialGroupsLocal[`${socialGroupIndex}`] },
                    socialGroupIdentitysLocal = [...socialGroupLocal.socialGroupIdentitys],
                    existingIdentity = socialGroupIdentitysLocal.findIndex(i => { return i.identity === body.identity });

                // check for an existing identity, if one is found then replace it in the state
                if (existingIdentity > -1) {
                    socialGroupIdentitysLocal[`${existingIdentity}`] = body;
                } else {
                    socialGroupIdentitysLocal.push(body);
                }
                socialGroupsLocal[`${socialGroupIndex}`].socialGroupIdentitys = socialGroupIdentitysLocal;

                setApp(app => {
                    return { ...app, 'socialGroups': socialGroupsLocal }
                });
                setShowPagesModal(false);
            }
        } catch (err) {
            setApp(prevApp);
            onError(err);
        } finally {
            //
        }
    };

    return (
        <div className='App'>
            {pageLoading && !app ?
                <Loading pageLoading={pageLoading} /> :
                <Container>
                    <Suspense fallback={<Loading pageLoading={true} />}>
                        <MetricSummary app={app} groups={app.socialGroups} metricLevel={3} />
                    </Suspense>
                    <Suspense fallback={<Loading pageLoading={true} />}>
                        <AddIdentityModal
                            socialGroup={socialGroup}
                            setSocialGroup={setSocialGroup}
                            app={app}
                            setApp={setApp}
                            showSocialGroupIdentities={showSocialGroupIdentities}
                            setShowSocialGroupIdentities={setShowSocialGroupIdentities}
                            // isWithinLimit={isWithinProfileLimit}
                            isAddingIdentity={isAddingIdentity}
                            setIsAddingIdentity={setIsAddingIdentity}
                            toggleShowSocialGroupIdentities={toggleShowSocialGroupIdentities}
                            handleCloseAddIdentity={handleCloseAddIdentity}
                            setPagesCompaniesBoards={savePagesCompaniesBoardsList}
                            isListLoading={isListLoading}
                            setIsListLoading={setIsListLoading}
                            isRefreshing={isRefreshing}
                            setShowPagesModal={setShowPagesModal}
                            setPages={setPages}
                            identity={identity}
                            setIdentity={setIdentity}
                            identityIdClicked={identityIdClicked}
                            setIdentityIdClicked={setIdentityIdClicked}
                        />
                    </Suspense>
                    <Suspense fallback={<Loading pageLoading={true} />}>
                        <AddPageModal
                            app={app}
                            socialGroup={socialGroup}
                            identity={identity}
                            pages={pages}
                            showPages={showPagesModal}
                            savePageOrCompanyAsIdentity={savePageCompanyAsIdentity}
                            handleClose={handleClosePagesModal}
                            isAddingPage={isAddingPage}
                            setIsAddingPage={setIsAddingPage}
                        />
                    </Suspense>
                    <Row className='padTop padBottom'>
                        <Col>
                            <Row>
                                <Col md={10} sm={9}>
                                    <h2><FontAwesomeIcon icon={faUsers} />&nbsp;{app.name}&apos;s Social Groups</h2>
                                </Col>
                                <Col md={2} sm={3} className='text-right'>
                                    {app && addNewGroupButton()}
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={2} sm={9} className='smaller-label'>Name</Col>
                        <Col md={2} className='smaller-label d-none d-md-block'>Identity Profiles</Col>
                        <Col md={2} className='smaller-label d-none d-md-block'>Posts Today</Col>
                        <Col md={2} className='smaller-label d-none d-md-block'>Posts this Month</Col>
                        <Col md={2} className='smaller-label d-none d-md-block'>Total Shares</Col>
                        <Col md={1} sm={3}>
                            &nbsp;
                        </Col>
                    </Row>

                    {
                        app.socialGroups.map(sg => {
                            if (canEdit && isEditingGroup === sg.id) {
                                return (
                                    <Suspense key={sg.id} fallback={<Loading pageLoading={true} />}>
                                        <SocialGroupRowEdit
                                            socialGroup={socialGroup}
                                            setSocialGroup={setSocialGroup}
                                            onCancel={toggleGroupEditMode}
                                            isLoading={isLoading}
                                            setIsLoading={setIsLoading}
                                            isEditingGroup={isEditingGroup}
                                            setIsEditingGroup={setIsEditingGroup}
                                            app={app}
                                            setApp={setApp}
                                        />
                                    </Suspense>
                                );
                            } else {
                                return (
                                    <Suspense key={sg.id} fallback={<Loading pageLoading={true} />}>
                                        <SocialGroupRow
                                            app={app}
                                            group={sg}
                                            canEdit={canEdit}
                                            onClickShow={toggleShowSocialGroupIdentities}
                                            onClickEdit={toggleGroupEditMode}
                                            onClickRemove={onClickRemoveSocialGroup}
                                        />
                                    </Suspense>
                                );
                            }
                        })
                    }
                    {
                        canEdit && app.isWithinGroupLimit && isAddingGroup &&
                        <Suspense fallback={<Loading pageLoading={true} />}>
                            <AddSocialGroupForm
                                socialGroup={socialGroup}
                                validate={validate}
                                onChange={onChange}
                                onSubmit={onSubmit}
                                onCancel={toggleGroupAddMode}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                            />
                        </Suspense>
                    }
                </Container>
            }
        </div>
    );
}
