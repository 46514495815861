import React, { ReactComponentElement } from 'react';
import { Row, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDrag } from 'react-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faArrowsAlt, faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { camelCase, pastTense, scrollTop } from '../../../../assets/scripts/utilsLib';
import { formatDate, getWeekInMonth, getWeekday } from '../../../../assets/scripts/dateLib';
import { OverlayMove } from './OverlayMove';
import config from '../../../../config';

/**
 * Builds and displays an html row for the calendar item
 * @param {object} argv object defining properties to use in this function
 * @param {object} argv.draft post object
 * @param {boolean} argv.showActions true or false to display action buttons
 * @param {Function} argv.onChange function to track/update the state upon text field changes
 * @param {Function} argv.onClickDelete function to control the deletion of the post
 * @param {boolean} argv.canEdit whether or not the application has a subscription and can be used/modified
 * @param {string} argv.query referring page
 * @param {boolean} argv.isMoving whether or not the post is scheduling days
 * @returns {HTMLElement} html
 */
export function DraftItem({
    draft,
    showActions,
    onChange, // eslint-disable-line no-unused-vars
    onClickDelete,
    canEdit,
    query,
    isMoving
}) {

    const addedDate = draft.addedAt, // getLocalDateTime(draft.addedAt),
        [{ isDragging }, drag] = useDrag({
            type: config.itemTypes.DRAFT,
            item: { type: config.itemTypes.DRAFT, postId: draft.postId, week: getWeekInMonth(draft.scheduledPostDate || draft.addedAt) - 1, day: getWeekday(draft.scheduledPostDate || draft.addedAt) },
            collect: monitor => ({
                isDragging: !!monitor.isDragging()
            })
        });

    /**
     * Builds the html to display the button dropdown
     * @param {object} draft object with content to display
     * @returns {ReactComponentElement} html
     */
    const renderDropdown = (draft) => {
        return (
            <Col sm={3} md={3} className='smaller'>
                <OverlayTrigger
                    trigger='click'
                    placement='bottom'
                    overlay={
                        <Popover placement='bottom'>
                            <Popover.Content>
                                <Link id={draft.postId} to={`/${draft.applicationId}/social/${draft.socialGroupId}/post/master/${draft.socialPostCode}/edit`} className='edit-post button tiny'>Edit</Link>
                                <Link id={draft.postId} to={`/${draft.applicationId}/social/${draft.socialGroupId}/post/master/${draft.socialPostCode}/delete`} onClick={onClickDelete} className='delete-post button tiny alert' title='This will only be deleted from Stitchz, not the post sent to the social media service'>Delete</Link>
                            </Popover.Content>
                        </Popover>
                    }
                />
            </Col>
        );
    };

    /**
     * Conver the post's numeric status to its string representation
     * @param {number} status value representing the post's status
     * @returns {string} string representation of the post's status
     */
    const renderPostStatus = (status) => {
        let ps = status;

        Object.keys(config.postStatus).forEach(key => {
            if (config.postStatus[`${key}`] === status) {
                ps = pastTense(camelCase(key));
            }
        });

        return `${ps} on`;
    };

    return (
        <div
            ref={drag}
            style={{
                opacity: isDragging ? 0.5 : 1
            }}
            key={draft.postId}
            className={'calendar-item ui-sortable-handle'}
        >
            {isMoving && <OverlayMove />}
            <Row>
                <Col>
                    <OverlayTrigger
                        trigger='click'
                        placement='bottom'
                        overlay={canEdit &&
                            <Popover placement='bottom' className='mini-menu'>
                                <Popover.Body>
                                    <div><Link to={`/${draft.applicationId}/social/${draft.socialGroupId}/post/master/${draft.socialPostCode}${query}`} onClick={scrollTop} className='edit-post'>Edit</Link></div>
                                    <div><Link to={`/${draft.applicationId}/social/${draft.socialGroupId}/post/master/${draft.socialPostCode}/delete`} id={draft.postId} onClick={onClickDelete} className='delete-post'>Delete</Link></div>
                                </Popover.Body>
                            </Popover>
                        }
                    >
                        <FontAwesomeIcon icon={faEllipsisV} title='Options' />
                    </OverlayTrigger>
                </Col>
                <Col sm={6} md={6} className='text-right'>
                    <div className='move-anchor' title='Move'><FontAwesomeIcon icon={faArrowsAlt} /></div>
                </Col>
            </Row>
            <Row title={`Created on ${formatDate(addedDate, 'dd-MMM-yyyy HH:mm')}`}>
                <Col sm={12}>
                    <OverlayTrigger
                        trigger='click'
                        placement='bottom'
                        overlay={canEdit &&
                            <Popover placement='bottom'>
                                <Popover.Body>
                                    <div id={`post-details-${draft.postId}`} data-dropdown-content className='f-dropdown content' aria-hidden='true' tabIndex='-1'>

                                        <div className='social-post'>
                                            <Row className='social-post-main-content'>
                                                <Col lg={2} className='d-sm-none d-md-block'>
                                                    <div>
                                                        n/a
                                                    </div>
                                                </Col>

                                                <Col sm={6} md={6}>
                                                    <div className='social-post-content'>
                                                        {draft.title || 'Draft post...'}
                                                    </div>
                                                </Col>

                                                <Col sm={2} md={2} className='text-smaller'>
                                                    {renderPostStatus(draft.postStatus)}
                                                </Col>

                                                <Col sm={2} md={2} className='text-smaller'>
                                                    <div className='date-posted' title='Create date'>
                                                        <FontAwesomeIcon icon={faClock} /> <time>{formatDate(draft.addedAt, 'dd-MMM-yyyy HH:mm')}</time>
                                                    </div>
                                                </Col>

                                                {showActions && renderDropdown(draft)}

                                            </Row>
                                            <Row className='social-post-secondary-content'>
                                                <Col sm={6} md={1} className='text-smaller'>
                                                    &nbsp;
                                                </Col>

                                                <Col sm={6} md={11} className='text-smaller'>
                                                    <div className='date-scheduled' title='modified date'>
                                                        <FontAwesomeIcon icon={faClock} /> <time>{formatDate(draft.addedAt, 'dd-MMM-yyyy HH:mm')}</time>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>

                                    </div>
                                </Popover.Body>
                            </Popover>
                        }
                    >
                        <Row>
                            <Col>
                                <div>
                                    {draft.title || 'Draft post...'}
                                </div>
                                <Row>
                                    {
                                        draft.scheduledPostDate &&
                                        <Col sm='auto' md='auto' className='text-smaller'>
                                            <div className='date-posted' title='scheduled post date'>
                                                Scheduled for <FontAwesomeIcon icon={faClock} /> <time>{formatDate(draft.scheduledPostDate, 'dd-MMM-yyyy HH:mm')}</time>
                                            </div>
                                        </Col>
                                    }
                                    <Col sm='auto' md='auto' className='text-smaller'>
                                        <span className='scheduled-time'>Created on {formatDate(addedDate, 'dd-MMM-yyyy HH:mm')}</span>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </OverlayTrigger>
                </Col>
            </Row>
        </div>
    );
}
