import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { acceptMemberRequest } from '../../libs/Api/members';
import { adjustHeader } from '../../../assets/scripts/utilsLib';
import { useRouter } from '../../../assets/scripts/hooksLib';
import { onError } from '../../../assets/scripts/errorLib';
// import '../../scss/app.scss';

/**
 * New Member page
 * @returns {HTMLElement} html for new member block
 */
export function NewMember() {
    const router = useRouter();

    // initiate accept member request
    useEffect(() => {
        let isMounted = true;

        const onLoad = async () => {
            try {
                adjustHeader();
                const { status, message, body } = await acceptMemberRequest(router.query.id, router.query.token)
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.response && err.response.data ? err.response.data.message : err.message
                        };
                    });

                if (status !== 'OK') {
                    onError(message);
                } else {
                    router.history(`/${body.applicationId}`);
                }
            } catch (e) {
                if (e.response && e.response.status === 404) {
                    router.history('/apps');
                } else if (e === 'not authenticated' || e.response && e.response.status === 401) {
                    // router.history(`/login?redirect=${router.pathname}${router.query}`);
                    window.location.href = `../login.html?redirect=${router.pathname}${router.query}`;
                } else {
                    onError(e);
                }
            } finally {
                //
            }
        }

        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, [router]);

    return (
        <div className='App'>
            <Container>
                <Row style={{ height: '65vh', paddingTop: '1rem' }}>
                    <Col>
                        <h3 style={{ textAlign: 'center', paddingBottom: '1rem' }}>
                            Assigning you to your team...
                        </h3>
                        <h3 style={{ textAlign: 'center' }}>
                            <FontAwesomeIcon icon={faSpinner} size='9x' pulse />
                        </h3>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
