import { post } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';

/**
 * Sends a txt message to chatgpt and waits for response
 * @param {string} message text message to send to chat gpt
 * @returns {Promise} http message response promise
 */
export const sendChatGPTMessage = async (message) => {
    try {
        const session = await fetchAuthSession(),
            token = session.tokens?.accessToken;

        // setup the base request body
        let requestBody = {
            message: message
        };

        const { body } = await post({
            apiName: 'stitchz-web-api',
            path: '/chatgpt/message',
            options: {
                headers: {
                    Authorization: token
                },
                body: requestBody
            }
        }).response;

        return await body.json();
    } catch (e) {
        return Promise.reject(e.message);
    } finally {
        //
    }
};
