// import React, { ReactComponent } from 'react';
import { ReactComponent } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppContext } from '../assets/scripts/contextLib';
import { useRouter } from '../assets/scripts/hooksLib';

/**
 * Authenticated route element
 * @param {object} argv object defining properties to use in this function
 * @param {ReactComponent} argv.children child react component
 * @param {object} argv.rest react properties on the parent component
 * @returns {HTMLElement} html for authenticated element block
 */
export default function AuthenticatedRoute({ children, ...rest }) { // eslint-disable-line no-unused-vars
    const { pathname, search } = useLocation();
    const { isAuthenticated } = useAppContext();
    const router = useRouter();

    return isAuthenticated ?
        children
        :
        // <Navigate to={
        //     `../login.html?redirect=${pathname}${search}`
        // } />
        window.location.href = `${router.origin}/login.html?redirect=${pathname ? `/app${pathname}` : ''}${search}`
    ;
}
