import React, { useEffect, useState, Suspense } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { fetchAuthSession } from 'aws-amplify/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersCog } from '@fortawesome/free-solid-svg-icons';
import { getUsersList, getUser, enableDisableUser, confirmUser, removeUser } from '../../app/libs/Api/users';
import { getConfigApplications, getConfigApplicationDetails } from '../../app/libs/Api/application';
import { Loading } from '../../components/Loading';
import { useToggle, useRouter } from '../../assets/scripts/hooksLib';
import { adjustHeader, nearestAncestorWithHref } from '../../assets/scripts/utilsLib';
import { onError } from '../../assets/scripts/errorLib';
import config from '../../config';

const UserList = React.lazy(() => import('../components/Users/Index'));
const AppList = React.lazy(() => import('../components/Apps/Index'));

/**
 * Administrator Index page
 * @returns {HTMLElement} html for adminitrator block
 */
export default function Index() {
    const router = useRouter();
    const [pageLoading, setPageLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [userSearch, setUserSearch] = useState('');
    const [userList, setUserList] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [isEditingUser, setIsEditingUser] = useToggle(false);
    const [app, setApp] = useState(null);
    const [appSearch, setAppSearch] = useState('');
    const [appList, setAppList] = useState([]);
    const [isEditingApp, setIsEditingApp] = useToggle(false);
    const [stripe, setStripe] = useState(null); // eslint-disable-line no-unused-vars

    /**
     * @typedef {{
     * onkeyup: number
     * }} KeyEvent
     */

    // confirm current user is authorized to be here
    useEffect(() => {
        let isMounted = true;

        /**
         * Asynchronis function laoding page events
         */
        async function onLoad() {
            try {
                adjustHeader();

                const session = await fetchAuthSession();

                // if the authenticated user is NOT a member of the group then redirect them elsewhere
                if (!session.tokens?.idToken
                    || !session.tokens?.idToken?.payload
                    || !session.tokens?.idToken?.payload['cognito:groups']
                    || !session.tokens?.idToken?.payload['cognito:groups'].indexOf('Administrators') === -1) {
                    router.history('/apps');
                }

                setPageLoading(false);
            } catch (e) {
                console.log(e);
            }
        }
        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, [router, setPageLoading]);

    // load users list
    useEffect(() => {
        let isMounted = true;

        const onLoad = async () => {
            try {
                const { status, message, body } = await getUsersList()
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.response && err.response.data ? err.response.data.message : err.message
                        };
                    });

                if (status !== 'OK') {
                    onError(message);
                } else {
                    setAllUsers(body.Users);
                    setUserList(body.Users);
                }
            } catch (e) {
                onError(e);
            } finally {
                //
            }
        }

        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, []);

    // load apps list
    useEffect(() => {
        let isMounted = true;

        const onLoad = async () => {
            try {
                const { status, message, body } = await getConfigApplications()
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.response && err.response.data ? err.response.data.message : err.message
                        };
                    });

                if (status !== 'OK') {
                    onError(message);
                } else {
                    setAppList(body.Items);
                }
            } catch (e) {
                onError(e);
            } finally {
                //
            }
        }

        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, []);

    // load stripe
    useEffect(() => {
        // dynamically add the Stripe JS to the page
        const script = document.createElement('script');
        script.src = 'https://js.stripe.com/v3/';
        script.async = true;
        script.setAttribute('id', 'stripe-js');
        document.body.appendChild(script);

        if (window.Stripe) {
            setStripe(window.Stripe(config.STRIPE_KEY));
        } else {
            document.querySelector('#stripe-js').addEventListener('load', () => {
                // Create Stripe instance once Stripe.js loads
                setStripe(window.Stripe(config.STRIPE_KEY));
            });
        }

        // clean up the Stripe JS script when we leave
        return () => {
            document.body.removeChild(script);
        };
    }, [setStripe]);

    /**
     * Populates the users per app for display
     * @param {KeyEvent} e key press event
     * @returns {void}
     */
    const loadUsersApps = (e) => {
        if (e) {
            e.preventDefault();
        }

        const node = nearestAncestorWithHref(e.target),
            id = node && node.id,
            nextUserList = [],
            app = appList.find(a => {
                return a.applicationId === id;
            });

        if (app) {
            for (let i = 0; i < app.applicationMembers.length; i++) {
                const member = app.applicationMembers[`${i}`],
                    found = allUsers.find(a => {
                        console.log(member);
                        console.log(a.Username);
                        return member === a.Username;
                    });

                if (found) {
                    found.Apps = [app.name];
                    nextUserList.push(found);
                }
            }

            setUserList(nextUserList);
        } else {
            setUserList(allUsers);
        }
    };

    /**
     * Handles the onChange event for user search
     * @param {KeyEvent} e key press event
     * @returns {void}
     */
    const onSearchUsernameChange = (e) => {
        const val = e.target.value;

        setUserSearch(val);
    };

    /**
     * Searches the user list by username keyword
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const searchByUsername = async (e) => {
        if (e) {
            e.preventDefault();
        }

        try {
            const { status, message, body } = await getUsersList(userSearch)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.response && err.response.data ? err.response.data.message : err.message
                    };
                });

            if (status !== 'OK') {
                onError(message);
            } else {
                setUserList(body.Users);
            }
        } catch (e) {
            onError(e);
        } finally {
            //
        }

        return;
    };

    /**
     * Queries user database for username and switches back and forth between read and write modes
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const toggleEditUser = async (e) => {
        if (e) {
            e.preventDefault();
        }

        if (isEditingUser) {
            setIsEditingUser(false);
            return;
        }

        const node = nearestAncestorWithHref(e.target),
            id = node && node.id;

        if (id) {
            try {
                const { status, message, body } = await getUser(id)
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.response && err.response.data ? err.response.data.message : err.message
                        };
                    });

                if (status !== 'OK') {
                    onError(message);
                } else {
                    setUser(body);
                    setIsEditingUser(true);
                }
            } catch (e) {
                onError(e);
            } finally {
                //
            }
        }
        return;
    };

    /**
     * Enables or Disables the current user in Cognito
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const toggleEnableDisableUser = async (e) => {
        e.preventDefault();

        if (user) {
            const y = window.confirm(`Would you like to ${user.Enabled ? 'Disable' : 'Enable'} this user?`);

            if (y) {
                try {
                    const { status, message } = await enableDisableUser(user.Username, !user.Enabled)
                        .catch(err => {
                            return {
                                status: 'failed',
                                message: err.response && err.response.data ? err.response.data.message : err.message
                            };
                        });

                    if (status !== 'OK') {
                        onError(message);
                    } else {
                        setUser(user => {
                            return { ...user, 'Enabled': !user.Enabled }
                        });
                        alert(`User has been ${user.Enabled ? 'Disabled' : 'Enabled'}`);
                    }
                } catch (e) {
                    onError(e);
                } finally {
                    //
                }
            }
        }

        return;
    };

    /**
     * Confirms the current user without a confirmation token in Cognito
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const confirmUserAccount = async (e) => {
        e.preventDefault();

        if (user) {
            const y = window.confirm('Would you like to confirm this user without a confirmation token?');

            if (y) {
                try {
                    const { status, message, body } = await confirmUser(user.Username)
                        .catch(err => {
                            return {
                                status: 'failed',
                                message: err.response && err.response.data ? err.response.data.message : err.message
                            };
                        });

                    if (status !== 'OK') {
                        onError(message);
                    } else {
                        console.log(body);
                        // setUser(user => {
                        //     return { ...user, 'UserStatus': 'CONFIRMED' }
                        // });
                        alert('User has been Confirmed');
                    }
                } catch (e) {
                    onError(e);
                } finally {
                    //
                }
            }
        }

        return;
    };

    /**
     * Deletes the current user account in Cognito
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const removeUserAccount = async (e) => {
        e.preventDefault();

        if (user) {
            const y = window.confirm('Would you like to permanently remove this user?');

            if (y) {
                const prevState = { ...userList },
                    nextState = { ...prevState },
                    index = nextState.findIndex(u => {
                        return u.Username === user.Username;
                    });

                if (index > -1) {
                    setUserList(nextState.splice(index, 1));
                }

                try {
                    const { status, message, body } = await removeUser(user.Username)
                        .catch(err => {
                            return {
                                status: 'failed',
                                message: err.response && err.response.data ? err.response.data.message : err.message
                            };
                        });

                    if (status !== 'OK') {
                        onError(message);
                    } else {
                        console.log(body);

                        alert('User has been Removed');
                    }
                } catch (e) {
                    onError(e);
                } finally {
                    //
                }
            }
        }

        return;
    };

    /**
     * Handles the onChange event for user search
     * @param {KeyEvent} e key press event
     * @returns {void}
     */
    const onSearchAppChange = (e) => {
        const val = e.target.value;

        setAppSearch(val);
    };

    /**
     * Searches the app list by name keyword
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const searchByAppName = async (e) => {
        if (e) {
            e.preventDefault();
        }

        try {
            const { status, message, body } = await getConfigApplications(appSearch)
                .catch(err => {
                    return {
                        status: 'failed',
                        message: err.response && err.response.data ? err.response.data.message : err.message
                    };
                });

            if (status !== 'OK') {
                onError(message);
            } else {
                setAppList(body.Items);
            }
        } catch (e) {
            onError(e);
        } finally {
            //
        }

        return;
    };

    /**
     * Queries user database for username and switches back and forth between read and write modes
     * @param {MouseEvent} e mouse click event
     * @returns {void}
     */
    const toggleEditApp = async (e) => {
        if (e) {
            e.preventDefault();
        }

        if (isEditingApp) {
            setIsEditingApp(false);
            return;
        }

        const node = nearestAncestorWithHref(e.target),
            id = node && node.id;

        if (id) {
            try {
                const { status, message, body } = await getConfigApplicationDetails(id)
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.response && err.response.data ? err.response.data.message : err.message
                        };
                    });

                if (status !== 'OK') {
                    onError(message);
                } else {
                    setApp(body);
                    setIsEditingApp(true);
                }
            } catch (e) {
                onError(e);
            } finally {
                //
            }
        }
        return;
    };

    return (
        <div className='App'>
            {pageLoading ?
                <Loading pageLoading={pageLoading} /> :
                <Container>
                    <h1><FontAwesomeIcon icon={faUsersCog} />&nbsp;Stitchz Dashboard</h1>
                    <Row>
                        <Col md={3}>
                            <div className='app-stats'>
                                <div className='app-stat'>
                                    <div className='app-stat-header'>
                                        Total Apps
                                    </div>
                                    <div className='app-stat-body'>
                                        {appList && appList.length}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={3}>
                            <div className='user-stats'>
                                <div className='user-stat'>
                                    <div className='user-stat-header'>
                                        Total Users
                                    </div>
                                    <div className='user-stat-body'>
                                        {userList && userList.length}
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='stripe-balance-summary'>
                                Stripe Analitics... (i.e. total monthly charges, payments, etc.)
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <Suspense fallback={<Loading pageLoading={true} />}>
                                <AppList
                                    appList={appList}
                                    keyword={appSearch}
                                    onKeywordChange={onSearchAppChange}
                                    onSearch={searchByAppName}
                                    isEditingApp={isEditingApp}
                                    toggleEditApp={toggleEditApp}
                                    app={app}
                                    onClick={loadUsersApps}
                                />
                            </Suspense>
                        </Col>
                        <Col md={8}>
                            <Suspense fallback={<Loading pageLoading={true} />}>
                                <UserList
                                    userList={userList}
                                    keyword={userSearch}
                                    onKeywordChange={onSearchUsernameChange}
                                    onSearch={searchByUsername}
                                    isEditingUser={isEditingUser}
                                    toggleEditUser={toggleEditUser}
                                    user={user}
                                    enableDisableUser={toggleEnableDisableUser}
                                    confirmUserAccount={confirmUserAccount}
                                    removeUserAccount={removeUserAccount}
                                />
                            </Suspense>
                        </Col>
                    </Row>
                </Container>
            }
        </div>
    );

}