
export const subscriptionTypes = [
    { id: 1, name: 'Administrator', price: 0.00, description: '', maxApplicationLimit: 0, maxApiCallLimit: 0, monthlyRequestLimit: 0, maxProviderLimit: 0, maxDeveloperLimit: 0, socialGroupLimit: 0, maxMonthlyShareLimit: 0, maxDailyShareLimit: 0, maxSocialGroupMemberLimit: 0, maxSocialProfileLimit: 0, isActive: true },
    { id: 2, name: 'Enterprise', price: 19.99, trial: 30, description: '', maxApplicationLimit: 1, maxApiCallLimit: 15000, monthlyRequestLimit: 15000, maxProviderLimit: 10, maxDeveloperLimit: 0, socialGroupLimit: 4, maxMonthlyShareLimit: 300, maxDailyShareLimit: 10, maxSocialGroupMemberLimit: 10, maxSocialProfileLimit: 15, isActive: true },
    { id: 3, name: 'Professional', price: 9.99, trial: 30, description: '', maxApplicationLimit: 1, maxApiCallLimit: 6000, monthlyRequestLimit: 6000, maxProviderLimit: 4, maxDeveloperLimit: 4, socialGroupLimit: 2, maxMonthlyShareLimit: 100, maxDailyShareLimit: 5, maxSocialGroupMemberLimit: 4, maxSocialProfileLimit: 6, isActive: true },
    { id: 4, name: 'Beginner', price: 1.99, trial: 30, description: '30 Day Free Trial. A great package to start out and learn how to use Stitchz!', maxApplicationLimit: 1, maxApiCallLimit: 1000, monthlyRequestLimit: 1000, maxProviderLimit: 2, maxDeveloperLimit: 1, socialGroupLimit: 2, maxMonthlyShareLimit: 20, maxDailyShareLimit: 1, maxSocialGroupMemberLimit: 1, maxSocialProfileLimit: 2, subscriptionSocialGroupLimit: 1, isActive: true },
    { id: 5, name: 'Starter', price: 0.00, trial: 0, description: 'A great free start to learn how to use Stitchz!', maxApplicationLimit: 1, maxApiCallLimit: 1000, monthlyRequestLimit: 1000, maxProviderLimit: 2, maxDeveloperLimit: 1, socialGroupLimit: 1, maxMonthlyShareLimit: 10, maxDailyShareLimit: 1, maxSocialGroupMemberLimit: 1, maxSocialProfileLimit: 2, subscriptionSocialGroupLimit: 1, isActive: true }
];

export const providers = [
    { id: 1, name: 'Facebook', displayName: 'Facebook Login', protocolVersion: 'v20', tokenEndpoint: 'https://graph.facebook.com/oauth/access_token', authorizationEndpoint: 'https://www.facebook.com/dialog/oauth', profileUrl: 'https://graph.facebook.com/me', canManageScope: true, configIdRequired: false, scope: 'email, pages_show_list, pages_manage_posts, pages_read_engagement, instagram_basic, instagram_content_publish', isEmailAvailable: true, isActive: true, iconName: 'facebook-F', socialCapabilities: 'text, link, photo, video' },
    { id: 2, name: 'Twitter', displayName: 'Twitter', protocolVersion: 'v1a', requestTokenEndpoint: 'https://api.twitter.com/oauth/request_token', authorizeTokenEndpoint: 'https://api.twitter.com/oauth/authorize', accessTokenEndpoint: 'https://api.twitter.com/oauth/access_token', profileUrl: 'https://api.twitter.com/1.1/account/verify_credentials.json', canManageScope: false, isEmailAvailable: false, isActive: true, iconName: 'twitter', socialCapabilities: 'text, photo, link' },
    { id: 3, name: 'Google', displayName: 'Google', protocolVersion: 'v20', tokenEndpoint: 'https://accounts.google.com/o/oauth2/token', authorizationEndpoint: 'https://accounts.google.com/o/oauth2/auth', profileUrl: 'https://www.googleapis.com/oauth2/v3/userinfo', canManageScope: true, configIdRequired: false, scope: 'openid profile email', isEmailAvailable: true, isActive: false, iconName: 'google', socialCapabilities: '' },
    { id: 4, name: 'OpenId', displayName: 'OpenId', protocolVersion: 'openid', tokenEndpoint: '', authorizationEndpoint: '', profileUrl: 'https://{0}/OpenId/v1/Authenticated', canManageScope: true, configIdRequired: false, scope: 'Email', isEmailAvailable: true, isActive: false, iconName: 'openid', socialCapabilities: '' },
    { id: 5, name: 'LinkedIn', displayName: 'LinkedIn', protocolVersion: 'v20', tokenEndpoint: 'https://www.linkedin.com/oauth/v2/accessToken', authorizationEndpoint: 'https://www.linkedin.com/oauth/v2/authorization', profileUrl: 'https://api.linkedin.com/v2/me', canManageScope: true, configIdRequired: false, scope: 'r_liteprofile r_basicprofile r_emailaddress w_member_social r_member_social r_organization_social w_organization_social rw_organization_admin', isEmailAvailable: true, isActive: true, iconName: 'linkedin-in', socialCapabilities: 'text, link' },
    { id: 6, name: 'Yahoo', displayName: 'Yahoo', protocolVersion: 'v1a', requestTokenEndpoint: 'https://api.login.yahoo.com/oauth/v2/get_request_token', authorizeTokenEndpoint: 'https://api.login.yahoo.com/oauth/v2/request_auth', accessTokenEndpoint: 'https://api.login.yahoo.com/oauth/v2/get_token', profileUrl: 'http://social.yahooapis.com/v1/user/{guid}/profile/usercard', canManageScope: true, isEmailAvailable: true, isActive: false, iconName: 'yahoo', socialCapabilities: '' },
    { id: 7, name: 'LiveConnect', displayName: 'Microsoft Live Connect', protocolVersion: 'v20', tokenEndpoint: 'https://login.live.com/oauth20_token.srf', authorizationEndpoint: 'https://login.live.com/oauth20_authorize.srf', profileUrl: 'https://apis.live.net/v5.0/me', canManageScope: true, configIdRequired: false, scope: 'wl.signin, wl.offline_access, wl.basic', isEmailAvailable: true, isActive: false, iconName: 'microsoft', socialCapabilities: '' },
    { id: 8, name: 'PLACEHOLDER - DO NOT USE', displayName: '-', protocolVersion: '-', profileUrl: '', isActive: false, iconName: 'biohazard' }, // to sync with prod we need to skip 1 number
    { id: 9, name: 'Paypal', displayName: 'Paypal', protocolVersion: 'v20', tokenEndpoint: 'https://api.sandbox.paypal.com/v1/oauth2/token', authorizationEndpoint: 'https://www.sandbox.paypal.com/signin/authorize', profileUrl: 'https://api.paypal.com/v1/identity/oauth2/userinfo', canManageScope: true, configIdRequired: false, scope: 'profile, email', isEmailAvailable: true, isActive: false, iconName: 'paypal', socialCapabilities: '' },
    { id: 10, name: 'tumblr', displayName: 'tumblr', protocolVersion: 'v1a', requestTokenEndpoint: 'https://www.tumblr.com/oauth/request_token', authorizeTokenEndpoint: 'https://www.tumblr.com/oauth/authorize', accessTokenEndpoint: 'https://www.tumblr.com/oauth/access_token', profileUrl: 'https://api.tumblr.com/v2/user/info', canManageScope: false, isEmailAvailable: false, isActive: true, iconName: 'tumblr', socialCapabilities: 'text, link, photo, audio, video' },
    { id: 11, name: 'Foursquare', displayName: 'FourSquare', protocolVersion: 'v20', tokenEndpoint: 'https://foursquare.com/oauth2/access_token', authorizationEndpoint: 'https://foursquare.com/oauth2/authorize', profileUrl: 'https://api.foursquare.com/v2/users/self', canManageScope: false, configIdRequired: false, isEmailAvailable: false, isActive: false, iconName: 'foursquare', socialCapabilities: '' },
    { id: 12, name: 'SalesForce', displayName: 'Sales Force', protocolVersion: 'v20', tokenEndpoint: 'https://login.salesforce.com/services/oauth2/token', authorizationEndpoint: 'https://login.salesforce.com/services/oauth2/authorize', profileUrl: 'https://login.salesforce.com/', canManageScope: true, configIdRequired: false, scope: 'id, api, refresh_token', isEmailAvailable: true, isActive: false, iconName: 'salesforce', socialCapabilities: '' },
    { id: 13, name: 'Flickr', displayName: 'Flickr', protocolVersion: 'v1a', requestTokenEndpoint: 'https://secure.flickr.com/services/oauth/request_token', authorizeTokenEndpoint: 'https://secure.flickr.com/services/oauth/authorize', accessTokenEndpoint: 'https://secure.flickr.com/services/oauth/access_token', profileUrl: 'https://secure.flickr.com/services/rest', canManageScope: false, isEmailAvailable: false, isActive: false, iconName: 'flickr', socialCapabilities: 'photo' },
    { id: 14, name: 'Dropbox', displayName: 'Dropbox', protocolVersion: 'v20', tokenEndpoint: 'https://api.dropboxapi.com/oauth2/token', authorizationEndpoint: 'https://www.dropbox.com/oauth2/authorize', profileUrl: 'https://api.dropboxapi.com/2/users/get_current_account', canManageScope: false, configIdRequired: false, isEmailAvailable: true, isActive: false, iconName: 'dropbox', socialCapabilities: 'file' },
    { id: 15, name: 'Instagram', displayName: 'Instagram', protocolVersion: 'v20', tokenEndpoint: 'https://graph.facebook.com/oauth/access_token', authorizationEndpoint: 'https://www.facebook.com/dialog/oauth', profileUrl: 'https://graph.facebook.com/me/accounts', canManageScope: false, configIdRequired: false, scope: 'email, pages_show_list, pages_manage_posts, pages_read_engagement, instagram_basic, pages_show_list', isEmailAvailable: false, isActive: false, iconName: 'instagram', socialCapabilities: 'text, link, photo, video' }, 
    { id: 16, name: 'MySpace', displayName: 'MySpace', protocolVersion: 'v1a', requestTokenEndpoint: 'https://api.myspace.com/request_token', authorizeTokenEndpoint: 'https://api.myspace.com/authorize', accessTokenEndpoint: 'https://api.myspace.com/access_token', profileUrl: 'https://api.myspace.com/1.0/people/@me/@self', canManageScope: false, isEmailAvailable: true, isActive: false, iconName: 'biohazard', socialCapabilities: '' },
    { id: 17, name: 'Amazon', displayName: 'Login with Amazon', protocolVersion: 'v20', tokenEndpoint: 'https://api.amazon.com/auth/o2/token', authorizationEndpoint: 'https://www.amazon.com/ap/oa', profileUrl: 'https://api.amazon.com/user/profile', canManageScope: true, configIdRequired: false, scope: 'profile', isEmailAvailable: true, isActive: false, iconName: 'amazon', socialCapabilities: '' },
    { id: 18, name: 'GooglePlus', displayName: 'Google Plus', protocolVersion: 'v20', tokenEndpoint: 'https://accounts.google.com/o/oauth2/token', authorizationEndpoint: 'https://accounts.google.com/o/oauth2/auth', profileUrl: 'https://www.googleapis.com/plus/v1/people/me', canManageScope: true, configIdRequired: false, scope: 'https://www.googleapis.com/auth/plus.me', isEmailAvailable: true, isActive: false, iconName: 'google-plus-g', socialCapabilities: '' },
    { id: 19, name: 'Discogs', displayName: 'Discogs', protocolVersion: 'v1a', requestTokenEndpoint: 'https://api.discogs.com/oauth/request_token', authorizeTokenEndpoint: 'https://www.discogs.com/oauth/authorize', accessTokenEndpoint: 'https://api.discogs.com/oauth/access_token', profileUrl: 'https://api.discogs.com/oauth/identity', canManageScope: false, isEmailAvailable: true, isActive: false, iconName: 'discogs', socialCapabilities: '' },
    { id: 20, name: 'SoundCloud', displayName: 'SoundCloud', protocolVersion: 'v20', tokenEndpoint: 'https://api.soundcloud.com/oauth2/token', authorizationEndpoint: 'https://soundcloud.com/connect', profileUrl: 'https://api.soundcloud.com/me.json', canManageScope: false, configIdRequired: false, isEmailAvailable: false, isActive: false, iconName: 'soundcloud', socialCapabilities: '' },
    { id: 21, name: 'VK', displayName: 'VKontakte', protocolVersion: 'v20', tokenEndpoint: 'https://oauth.vk.com/access_token', authorizationEndpoint: 'https://oauth.vk.com/authorize', profileUrl: 'https://api.vk.com/method/users.get', canManageScope: true, configIdRequired: false, scope: 'offline', isEmailAvailable: true, isActive: false, iconName: 'vk', socialCapabilities: '' },
    { id: 22, name: 'Box', displayName: 'Box.com', protocolVersion: 'v20', tokenEndpoint: 'https://www.box.com/api/oauth2/token', authorizationEndpoint: 'https://www.box.com/api/oauth2/authorize', profileUrl: 'https://api.box.com/2.0/users/me', canManageScope: false, configIdRequired: false, isEmailAvailable: true, isActive: false, iconName: 'box', socialCapabilities: 'files' },
    { id: 23, name: 'AOL', displayName: 'Aol.com', protocolVersion: 'v20', tokenEndpoint: 'https://api.screenname.aol.com/auth/access_token', authorizationEndpoint: 'https://api.screenname.aol.com/auth/authorize', profileUrl: 'https://api.screenname.aol.com/auth/getUserData', canManageScope: true, configIdRequired: false, scope: 'basic', isEmailAvailable: true, isActive: false, iconName: 'biohazard', socialCapabilities: '' },
    { id: 24, name: 'Reddit', displayName: 'Reddit', protocolVersion: 'v20', tokenEndpoint: 'https://www.reddit.com/api/v1/access_token', authorizationEndpoint: 'https://www.reddit.com/api/v1/authorize', profileUrl: 'https://oauth.reddit.com/api/v1/me', canManageScope: true, configIdRequired: false, scope: 'identity read edit submit', isEmailAvailable: true, isActive: false, iconName: 'reddit', socialCapabilities: 'text, link' },
    { id: 25, name: 'Disqus', displayName: 'Disqus.com', protocolVersion: 'v20', tokenEndpoint: 'https://disqus.com/api/oauth/2.0/access_token/', authorizationEndpoint: 'https://disqus.com/api/oauth/2.0/authorize/', profileUrl: 'https://disqus.com/api/3.0/users/details.json', canManageScope: true, configIdRequired: false, scope: 'read, email, write', isEmailAvailable: true, isActive: false, iconName: 'disqus', socialCapabilities: '' },
    { id: 26, name: 'Github', displayName: 'Github.com', protocolVersion: 'v20', tokenEndpoint: 'https://github.com/login/oauth/access_token', authorizationEndpoint: 'https://github.com/login/oauth/authorize', profileUrl: 'https://api.github.com/user', canManageScope: true, configIdRequired: false, scope: 'user, user:email', isEmailAvailable: true, isActive: false, iconName: 'github', socialCapabilities: '' },
    { id: 27, name: 'Pinterest', displayName: 'Pinterest', protocolVersion: 'v20', tokenEndpoint: 'https://api.pinterest.com/v5/oauth/token', authorizationEndpoint: 'https://www.pinterest.com/oauth/', profileUrl: 'https://api.pinterest.com/user_account', canManageScope: true, configIdRequired: false, scope: 'ads:read,boards:read,boards:read_secret,boards:write,boards:write_secret,pins:read,pins:read_secret,pins:write,pins:write_secret,user_accounts:read,catalogs:read,catalogs:write', isEmailAvailable: false, isActive: true, iconName: 'pinterest', socialCapabilities: 'link, photo' },
    { id: 28, name: 'Plurk', displayName: 'Plurk', protocolVersion: 'v1a', requestTokenEndpoint: 'https://www.plurk.com/OAuth/request_token', authorizeTokenEndpoint: 'https://www.plurk.com/OAuth/authorize', accessTokenEndpoint: 'https://www.plurk.com/OAuth/access_token', profileUrl: 'https://www.plurk.com/APP/Profile/getOwnProfile', canManageScope: false, isEmailAvailable: false, isActive: true, iconName: 'plurk', socialCapabilities: 'text, link, photo' },
    { id: 29, name: 'Facebook', displayName: 'Facebook Login for Business', protocolVersion: 'v20', tokenEndpoint: 'https://graph.facebook.com/{platformVersion}/oauth/access_token', authorizationEndpoint: 'https://www.facebook.com/{platformVersion}/dialog/oauth', profileUrl: 'https://graph.facebook.com/me', canManageScope: false, configIdRequired: true, scope: '', isEmailAvailable: true, isActive: true, iconName: 'facebook-F', socialCapabilities: 'text, link, photo, video' },
    { id: 30, name: 'Facebook', displayName: 'Facebook Login for Business Pages', protocolVersion: 'v20', tokenEndpoint: 'https://graph.facebook.com/{platformVersion}/oauth/access_token', authorizationEndpoint: 'https://www.facebook.com/{platformVersion}/dialog/oauth', profileUrl: 'https://graph.facebook.com/me', canManageScope: false, configIdRequired: true, scope: '', isEmailAvailable: true, isActive: true, iconName: 'facebook-F', socialCapabilities: 'text, link, photo, video' },
    { id: 31, name: 'Twitter', displayName: 'Twitter v2.0', protocolVersion: 'v20', tokenEndpoint: 'https://api.twitter.com/2/oauth2/token', authorizeTokenEndpoint: 'https://twitter.com/i/oauth2/authorize', profileUrl: 'https://api.twitter.com/2/users/me', canManageScope: true, scope: 'tweet.read, tweet.write, tweet.moderate.write, users.read, follows.read, follows.write, offline.access, space.read, mute.read, mute.write, like.read, like.write, list.read, list.write, block.read, block.write, bookmark.read, bookmark.write', isEmailAvailable: false, isActive: true, iconName: 'twitter', socialCapabilities: 'text, photo, link' }
];

export const perms = [
    {
        label: 'read',
        id: 'new-member-read',
        value: 4
    },
    {
        label: 'write',
        id: 'new-member-write',
        value: 2
    },
    {
        label: 'full',
        id: 'new-member-full',
        value: 1
    }
];

export const activityType = {
    TEXT: 1,
    QUOTE: 2,
    LINK: 3,
    MEDIA: 4
};

export const postStatus = {
    SCHEDULED: 1,
    QUEUED: 2,
    POSTED: 3,
    ERRORED: 4,
    CANCELLED: 5,
    DRAFT: 6
};

export const AcceptableMediaTypes = [
    'image/png',
    'image/gif',
    'image/svg',
    'image/jpeg',
    'image/jpg',
    'image/webp'
];

export const ItemTypes = {
    POST: 'post',
    DRAFT: 'draft'
};

export const logLevel = [
    'DEBUG',
    'INFO',
    'WARN',
    'ERROR'
];

const region = 'us-east-1';

const development = {
    BASEURL: 'http://localhost:3000/',
    subscriptionTypes: subscriptionTypes,
    providers: providers,
    perms: perms,
    activityType: activityType,
    postStatus: postStatus,
    acceptableMediaTypes: AcceptableMediaTypes,
    itemTypes: ItemTypes,
    s3: {
        REGION: region,
        BUCKET: 'dev-stitchz-web-app'
    },
    apiGateway: {
        REGION: region,
        URL: 'https://dev-api.stitchz.net' // 'https://n8x37zka48.execute-api.us-east-1.amazonaws.com'
    },
    cognito: {
        REGION: region,
        USER_POOL_ID: 'us-east-1_RLhyRN0dl',
        APP_CLIENT_ID: '4vsigr2c0g9pfkl7qp35iopcvf',
        IDENTITY_POOL_ID: 'us-east-1:8ff1ceae-4f31-46fb-aecb-daa80f9ca62d'    // https://stackoverflow.com/a/46028801
    },
    STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,
    USERICON: 'https://dev-images.stitchz.net/images/user.png',
    OPENAI_SECRET_KEY: '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',
    logLevel: process.env.LOG_LEVEL || logLevel[0]
};

const blue = {
    BASEURL: 'https://www.stitchz.net/',
    subscriptionTypes: subscriptionTypes,
    providers: providers,
    perms: perms,
    activityType: activityType,
    postStatus: postStatus,
    acceptableMediaTypes: AcceptableMediaTypes,
    itemTypes: ItemTypes,
    s3: {
        REGION: region,
        BUCKET: 'stitchz-web-app'
    },
    apiGateway: {
        REGION: region,
        URL: 'https://api.stitchz.net'
    },
    cognito: {
        REGION: region,
        USER_POOL_ID: 'us-east-1_0OJStv0sl',
        APP_CLIENT_ID: 'nhslrtcb6ph7755brgtler69s',
        IDENTITY_POOL_ID: 'us-east-1:ecb666e8-fc51-4622-8ddc-656d3dc0753a'
    },
    STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,
    USERICON: 'https://images.stitchz.net/images/user.png',
    logLevel: process.env.LOG_LEVEL || logLevel[1]
};

const green = { // eslint-disable-line no-unused-vars
    BASEURL: 'https://www.stitchz.net/',
    subscriptionTypes: subscriptionTypes,
    providers: providers,
    perms: perms,
    activityType: activityType,
    postStatus: postStatus,
    AcceptableMediaTypes: AcceptableMediaTypes,
    itemTypes: ItemTypes,
    s3: {
        REGION: region,
        BUCKET: 'stitchz-web-app'
    },
    apiGateway: {
        REGION: region,
        URL: 'https://api.stitchz.net'
    },
    cognito: {
        REGION: region,
        USER_POOL_ID: 'us-east-1_ViZ1XFZ0X',
        APP_CLIENT_ID: '3eiq8cp59gtnile8bonsld4m42',
        IDENTITY_POOL_ID: 'us-east-1:07c49f7c-89ae-494a-abb2-de51f246a742'
    },
    STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,
    USERICON: 'https://images.stitchz.net/images/user.png',
    logLevel: process.env.LOG_LEVEL || logLevel[1]
};
// console.log(mode);
// Default to dev if not set
const config = process.env.REACT_APP_STAGE && process.env.REACT_APP_STAGE === 'production'
    ? blue : development;

if (process.env.REACT_APP_STAGE !== 'production') {
    console.log(`Running in ${process.env.REACT_APP_STAGE} mode (REACT_APP_STAGE)`);
    console.log(`Running in ${process.env.NODE_ENV} mode (NODE_ENV)`);
}

export default {
    // Add common config values here
    // MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};