import React from 'react';
import { Row, Col, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faLinkedin, faPinterest, faTumblr, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faCogs, faBullseye, faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import '../assets/styles/footer.scss';

/**
 * Internal Stitchz.net footer
 * @returns {HTMLElement} html of the internal (authenticated users) footer
 */
export default function InternalFooter() {

    return (
        <footer id='footer'>
            <Row className='footer3 justify-content-md-center'>
                <Col md={{ span: 3, offset: 1 }}>
                    <h6 className='footer-title-text'><FontAwesomeIcon icon={faGlobeAmericas} />&nbsp;Stitchz</h6>
                    <ul>
                        <li className='page_item'><a href='/'>Home</a></li>
                        {/* <li className='page_item'><a href='/Blog'>Blog</a></li> */}
                        <li className='page_item'><a href='/privacy.html'>Privacy Statement</a></li>
                        <li className='page_item'><a href='/conditions.html'>Terms &amp; Conditions</a></li>
                    </ul>
                </Col>
                <Col md={3}>
                    <h6 className='footer-title-text'><FontAwesomeIcon icon={faCogs} />&nbsp;Features</h6>

                    <ul>
                        <li className='page_item'><a href='/features.html'><span>Social Media</span></a></li>
                        <li className='page_item'><a href='/pricing.html'>Pricing</a></li>
                    </ul>
                </Col>
                <Col md={3}>
                    <h6 className='footer-title-text'><FontAwesomeIcon icon={faBullseye} />&nbsp;Resources</h6>

                    <ul>
                        <li className='page_item'><a href='/faq.html'>Faq</a></li>
                        {/* <li className='page_item'><a href='http://stitchz.uservoice.com/forums/81839?lang=en'>Help</a></li> */}
                        <li className='page_item'><a href='/contactus.html' rel='nofollow'>Contact Us</a></li>
                        <li className='page_item'><a className='footer-links' href='/sitemap.html'>Site Map</a></li>
                    </ul>
                </Col>
            </Row>

            <Row className='footer4 justify-content-md-center'>
                <Col md={6}>
                    <div id='bottom-text'>&copy; 2012-2024 ~ <a href='http://www.stitchz.net/'>Stitchz.net</a></div>
                </Col>
                <Col md={6}>
                    <ListGroup horizontal className='classic-social'>
                        <ListGroup.Item><a href='http://www.facebook.com/pages/Stitchz/129390430474917' className='classic-icon facebook' title='Follow us on Facebook' rel='nofollow'><FontAwesomeIcon icon={faFacebookF} /><span /></a></ListGroup.Item>
                        <ListGroup.Item><a href='http://www.twitter.com/stitchzdotnet' className='classic-icon twitter' title='Follow our tweets' rel='nofollow'><FontAwesomeIcon icon={faTwitter} /><span /></a></ListGroup.Item>
                        <ListGroup.Item><a href='http://stitchzdotnet.tumblr.com/' className='classic-icon tumblr' title='Follow our tumblog' rel='nofollow'><FontAwesomeIcon icon={faTumblr} /><span /></a></ListGroup.Item>
                        <ListGroup.Item><a href='https://www.linkedin.com/company/stitchz-social-login' className='classic-icon linkedin' title='Follow our linkedin page' rel='nofollow'><FontAwesomeIcon icon={faLinkedin} /><span /></a></ListGroup.Item>
                        <ListGroup.Item><a href='https://www.pinterest.com/stitchzdotnet/' className='classic-icon pinterest' title='Follow our Pins' rel='nofollow'><FontAwesomeIcon icon={faPinterest} /><span /></a></ListGroup.Item>
                    </ListGroup>
                    <div id='to-top' className='poshytip' title='To top' />
                </Col>
            </Row>
        </footer>
    );
}
