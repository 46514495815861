import React from 'react';
import {
    Row,
    Col,
    Container
} from 'react-bootstrap';

// source: https://alexlogorz.medium.com/simple-responsive-grid-system-using-react-and-bootstrap-2b322372b525

/*  This grid system takes an array of JSX items as children
    and calculates the number of rows needed based on chilren count and col count.
*/
const GridSystem = ({ colCount, children, md }) => {
    let rowCount = children ? Math.floor(children.length / colCount) + 1 : 0;

    // Index is needed to keep track of the current element that we are one.
    let index = 0;

    // This is the driver function for building the grid system.
    const buildGrid = () => {
        return (
            renderRows()
        );
    };

    // Returns For example, we can have a row with 2 columns inside it.
    const renderRows = () => {
        let rows = [];

        for (let row = 0; row < rowCount; row++) {
            rows.push(
                <Row className='Row' key={row}>
                    {
                        renderCols()
                    }
                </Row>
            )
        }

        return rows;
    };

    // Returns an array of columns with the children inside.
    const renderCols = () => {
        let cols = [];

        // If you want to add more bootstrap breakpoints you can pass them as props here.
        for (let col = 0; col < colCount; col++) {
            if (children && index < children.length) {
                cols.push(
                    <Col className='Col' md={md} key={col}>
                        {
                            // eslint-disable-next-line security/detect-object-injection
                            children[index]
                        }
                    </Col>
                )
                index++;
            }
        }

        return cols;
    };

    return (
        <Container className='Container'>
            {
                buildGrid()
            }
        </Container>
    );
};

export default GridSystem;