import React from 'react';
import { DraftItem } from './DraftItem';
import { PostItem } from './PostItem';
import config from '../../../../config';

/**
 * Builds the current day's calendar items
 * @param {object} argv object defining properties to use in this function
 * @param {Array} argv.todaysPosts collection of posts scheduled or added on the current date
 * @param {Array} argv.socialGroupIdentitys application social group identities that can be shared with
 * @param {string} argv.referer encoded uri that referred to this page
 * @param {boolean} argv.canEdit whether or not the application has a subscription and can be used/modified
 * @param {Function} argv.onClickEdit function to edit the post
 * @param {Function} argv.onChangeEdit function to track/update the state upon text field changes
 * @param {Function} argv.onClickDelete function to control the deletion of the post
 * @param {Function} argv.onClickReschedule function to control rescheduling the post
 * @param {Function} argv.onClickCopy function to control the copying of the post
 * @param {boolean} argv.isMoving whether or not the post is scheduling days
 * @returns {HTMLElement} html
 */
export function PostsByDay({
    todaysPosts,
    socialGroupIdentitys,
    referer,
    canEdit,
    onClickEdit, // eslint-disable-line no-unused-vars
    onChangeEdit,
    onClickDelete,
    onClickReschedule,
    onClickCopy,
    isMoving
}) {

    let shares = [];
    const identitys = socialGroupIdentitys && socialGroupIdentitys.map(sgi => {
            return {
                id: sgi.id,
                image: sgi.image,
                provider: config.providers.find(p => {
                    return p.id === sgi.providerId;
                })
            };
        }),
        query = `?ref=${referer}`;

    // loop over the posts and build out the Html
    todaysPosts && todaysPosts.forEach(tp => {
        if (tp) {
            if (!tp.identityId || tp.postStatus === config.postStatus.DRAFT) {
                shares.push(
                    <DraftItem
                        key={tp.postId}
                        draft={tp}
                        showActions={false}
                        onChange={onChangeEdit}
                        onClickDelete={onClickDelete}
                        canEdit={canEdit}
                        query={query}
                        isMoving={isMoving}
                    />
                );
            } else {
                const identity = identitys.find(i => {
                    return i.id === tp.identityId;
                })

                // an identity could be null/undefined if the Identity Provider has been removed from the social group
                // if (identity) {
                shares.push(
                    <PostItem
                        key={tp.postId}
                        post={tp}
                        identity={identity}
                        showActions={true}
                        onChange={onChangeEdit}
                        onClickReschedule={onClickReschedule}
                        onClickDelete={onClickDelete}
                        onClickCopy={onClickCopy}
                        canEdit={canEdit}
                        query={query}
                        isMoving={isMoving}
                    />
                );
                // }
            }
        }
    });

    return shares;
}
