import React, { useState, useEffect, Suspense } from 'react';
import { Container } from 'react-bootstrap';
import { getApplicationDetails } from '../../libs/Api/application';
import { Loading } from '../../../components/Loading';
import { useRouter } from '../../../assets/scripts/hooksLib';
import { adjustHeader } from '../../../assets/scripts/utilsLib';
import { onError } from '../../../assets/scripts/errorLib';
// import '../../scss/app.scss';

const MetricSummary = React.lazy(() => import('../../components/Application/MetricSummary'));

/**
 * Application Dashboard page
 * @returns {HTMLElement} html for dashboard block
 */
export function Dashboard() {
    const router = useRouter();
    const [pageLoading, setPageLoading] = useState(true);
    const [app, setApp] = useState(null);

    // load application info
    useEffect(() => {
        let isMounted = true;

        const onLoad = async () => {
            try {
                adjustHeader();

                const { status, message, body } = await getApplicationDetails(router.query.id)
                    .catch(err => {
                        return {
                            status: 'failed',
                            message: err.response && err.response.data ? err.response.data.message : err.message
                        };
                    });

                if (status !== 'OK') {
                    onError(message);
                } else {
                    setApp(body);
                }
            } catch (e) {
                if (e.response && e.response.status === 404) {
                    // router.push('/apps');
                    router.history('/apps');
                } else if (e === 'not authenticated' || e.response && e.response.status === 401) {
                    // router.history(`/login?redirect=${router.pathname}${router.query}`);
                    window.location.href = `../login.html?redirect=${router.pathname}${router.query}`;
                } else {
                    onError(e);
                }
            } finally {
                setPageLoading(false);
            }
        }

        if (isMounted) {
            onLoad()
                .catch(err => {
                    console.error(err);
                });
        }

        // clean up async calls
        return () => { isMounted = false; };
    }, [router, setApp, setPageLoading]);

    return (
        <div className='App'>
            {pageLoading && !app ?
                <Loading pageLoading={pageLoading} /> :
                <Container>
                    <h1>{app.name} Dashboard</h1>
                    <Suspense fallback={<Loading pageLoading={true} />}>
                        <MetricSummary socialGroup={app} app={app} />
                    </Suspense>
                    <Suspense fallback={<Loading pageLoading={true} />}>
                        <MetricSummary app={app} groups={app.socialGroups} metricLevel={3} />
                    </Suspense>
                    {app.socialGroups && app.socialGroups.map(sg => {
                        return <Suspense key={sg.id} fallback={<Loading pageLoading={true} />}>
                            <MetricSummary socialGroup={sg} app={app} metricLevel={2} />
                        </Suspense>;
                    })}
                </Container>
            }
        </div>
    );
}
